// This file is auto generated. Do not edit this file manually.

import KobleException from "./KobleException";

export class KobleErrors {
  static readonly ACADEMIC_PROJECT_ID_NOT_FOUND = new KobleException(
    "The provided academic project ID was not found.",
    "El id del proyecto académico proporcionado no fue encontrado.",
    "ACADEMIC_PROJECT_ID_NOT_FOUND"
  );

  static readonly ACADEMIC_PROJECT_INVALID_START_DATE = new KobleException(
    "The provided academic start date is invalid. It must be in the past.",
    "La fecha de inicio del project academico proporcionado no es válida. Debe ser en el pasado.",
    "ACADEMIC_PROJECT_INVALID_START_DATE"
  );

  static readonly ACADEMIC_PROJECT_INVALID_END_DATE = new KobleException(
    "The provided academic project end date is invalid. It must be greater than the start date.",
    "La fecha de fin proporcionada no es válida. Debe ser en una fecha futura a la fecha de inicio.",
    "ACADEMIC_PROJECT_INVALID_END_DATE"
  );

  static readonly ACADEMIC_PROJECT_INVALID_ABOUT = new KobleException(
    "The provided academic project about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, it should be between 1 and 500 characters, and no more than 20 lines",
    "La descripción del proyecto académico no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, debe tener entre 1 y 500 caracteres, y no más de 20 líneas.",
    "ACADEMIC_PROJECT_INVALID_ABOUT"
  );

  static readonly ACADEMIC_PROJECT_INVALID_TITLE = new KobleException(
    "The provided academic project title is invalid. It must only contain alpanumeric characters, spaces, digits, hyphens, and should be between 1 and 100 characters in length.",
    "El título proporcionado del project academico no es válido. Sólamente puede contener caracteres alfanuméricos, espacios, dígitos, guiones, y debe tener entre 1 y 100 caracteres.",
    "ACADEMIC_PROJECT_INVALID_TITLE"
  );

  static readonly ACADEMIC_PROJECT_INVALID_KEY_COMPETENCY_LIST =
    new KobleException(
      "The provided key competency list is invalid. It must contain between 1 and 5 elements.",
      "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 5 elementos.",
      "ACADEMIC_PROJECT_INVALID_KEY_COMPETENCY_LIST"
    );

  static readonly AREA_ID_NOT_FOUND = new KobleException(
    "The provided area ID could not be found.",
    "El identificador de área proporcionado no pudo ser encontrado.",
    "AREA_ID_NOT_FOUND"
  );

  static readonly BENEFIT_ID_NOT_FOUND = new KobleException(
    "The provided benefit ID could not be found.",
    "El identificador del beneficio proporcionado no se pudo encontrar.",
    "BENEFIT_ID_NOT_FOUND"
  );

  static readonly BOUNDING_BOX_INVALID_LATITUDE_RELATIONSHIP =
    new KobleException(
      "Northeast latitude must be greater than southwest latitude.",
      "La latitud noreste debe ser mayor que la latitud suroeste.",
      "BOUNDING_BOX_INVALID_LATITUDE_RELATIONSHIP"
    );

  static readonly BOUNDING_BOX_INVALID_LONGITUDE_RELATIONSHIP =
    new KobleException(
      "Northeast longitude must be greater than southwest longitude.",
      "La longitud noreste debe ser mayor que la longitud suroeste.",
      "BOUNDING_BOX_INVALID_LONGITUDE_RELATIONSHIP"
    );

  static readonly BUSINESS_ID_NOT_FOUND = new KobleException(
    "The provided business ID doesn't exist.",
    "El id de negocio proporcionado no existe.",
    "BUSINESS_ID_NOT_FOUND"
  );

  static readonly BUSINESS_UPDATE_PROFILE_PICTURE_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "You have exceeded the daily limit of profile picture uploads for this business.",
      "Has superado el límite diario de fotos de perfil subidas para este negocio.",
      "BUSINESS_UPDATE_PROFILE_PICTURE_DAILY_LIMIT_EXCEEDED"
    );

  static readonly BUSINESS_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "You have exceeded the daily limit of background picture uploads for this business.",
      "Has superado el límite diario de fotos de fondo subidas para este negocio.",
      "BUSINESS_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT_EXCEEDED"
    );

  static readonly BUSINESS_INVALID_NAME = new KobleException(
    "The provided business name is invalid. It must only contain alphanumeric characters, spaces, and permitted punctuation marks (commas, semicolons, periods, hyphens, underscores, parentheses), and it should be between 1 and 128 characters in length.",
    "El nombre de negocio proporcionado no es válido. Sólamente puede contener caracteres alfanuméricos, espacios y signos de puntuación permitidos (comas, puntos y comas, puntos, guiones, guiones bajos, paréntesis) y debe tener entre 1 y 128 caracteres de longitud.",
    "BUSINESS_INVALID_NAME"
  );

  static readonly BUSINESS_STRIPE_SUBSCRIPTION_URL_CREATION_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "You have exceeded the daily limit of 5 subscription URL creations per day for this business.",
      "Has superado el límite diario de 5 creaciones de URL de suscripción por día para este negocio.",
      "BUSINESS_STRIPE_SUBSCRIPTION_URL_CREATION_DAILY_LIMIT_EXCEEDED"
    );

  static readonly BUSINESS_INVALID_FOUNDATION_DATE = new KobleException(
    "The provided foundation date is invalid. It must be a date within the last 200 years.",
    "La fecha de fundación proporcionada no es válida. Debe ser una fecha dentro de los últimos 200 años.",
    "BUSINESS_INVALID_FOUNDATION_DATE"
  );

  static readonly BUSINESS_INVALID_SOCIAL_MEDIA_URL_LIST = new KobleException(
    "The provided social media URL list is invalid. It must contain 7 elements at most.",
    "La lista de URLs de redes sociales proporcionada es inválida. Debe contener 7 elementos como máximo.",
    "BUSINESS_INVALID_SOCIAL_MEDIA_URL_LIST"
  );

  static readonly BUSINESS_INVALID_WEBSITE_URL = new KobleException(
    "The provided website URL is invalid. It must be a well-formed, absolute URL (including protocol) of 256 characters at most.",
    "El URL del sitio web proporcionado no es válido. Debe ser una URL absoluta bien formada (incluyendo el protocolo) con un máximo de 256 caracteres.",
    "BUSINESS_INVALID_WEBSITE_URL"
  );

  static readonly BUSINESS_STRIPE_BILLING_PORTAL_URL_CREATION_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "You have exceeded the daily limit of 5 billing portal URL creations per day for this business.",
      "Has superado el límite diario de 5 creaciones de URL del portal de facturación por día para este negocio.",
      "BUSINESS_STRIPE_BILLING_PORTAL_URL_CREATION_DAILY_LIMIT_EXCEEDED"
    );

  static readonly BUSINESS_DOESNT_HAVE_STRIPE_CUSTOMER_ID = new KobleException(
    "The business doesn't have a stripe customer id.",
    "El negocio no tiene un identificador de cliente de Stripe.",
    "BUSINESS_DOESNT_HAVE_STRIPE_CUSTOMER_ID"
  );

  static readonly BUSINESS_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED =
    new KobleException(
      "The provided profile picture's size is too large. It must be less than 20MB.",
      "El tamaño de la foto de perfil proporcionada es demasiado grande. Debe ser menos de 20MB.",
      "BUSINESS_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED"
    );

  static readonly BUSINESS_BACKGROUND_IMAGE_SIZE_LIMIT_EXCEEDED =
    new KobleException(
      "The provided background picture's size is too large. It must be less than 20MB.",
      "El tamaño de la foto de fondo proporcionada es demasiado grande. Debe ser menos de 20MB.",
      "BUSINESS_BACKGROUND_IMAGE_SIZE_LIMIT_EXCEEDED"
    );

  static readonly BUSINESS_INVALID_PROFILE_IMAGE = new KobleException(
    "The provided profile picture is invalid. It must be a JPG, JPEG, PNG or GIF file.",
    "La foto de perfil proporcionada no es válida. Debe ser un archivo de tipo JPG, JPEG, PNG o GIF.",
    "BUSINESS_INVALID_PROFILE_IMAGE"
  );

  static readonly BUSINESS_INVALID_BACKGROUND_IMAGE = new KobleException(
    "The provided background picture is invalid. It must be a JPG, JPEG, PNG or GIF file.",
    "La foto de fondo proporcionada no es válida. Debe ser un archivo de tipo JPG, JPEG, PNG o GIF.",
    "BUSINESS_INVALID_BACKGROUND_IMAGE"
  );

  static readonly BUSINESS_INVALID_ABOUT = new KobleException(
    "The provided business about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 2500 characters in length.",
    "La descricpión de la empresa proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 2500 caracteres.",
    "BUSINESS_INVALID_ABOUT"
  );

  static readonly BUSINESS_INVALID_TYPICAL_DAY_DESCRIPTION = new KobleException(
    "The provided typical day description is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 1500 characters in length.",
    "La descripción del día típico proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 1500 caracteres.",
    "BUSINESS_INVALID_TYPICAL_DAY_DESCRIPTION"
  );

  static readonly BUSINESS_STRIPE_CUSTOMER_ID_NOT_FOUND = new KobleException(
    "The provided business Stripe customer ID doesn't exist.",
    "El identificador de cliente de Stripe proporcionado para el negocio no existe.",
    "BUSINESS_STRIPE_CUSTOMER_ID_NOT_FOUND"
  );

  static readonly BUSINESS_NO_JOB_SLOTS_AVAILABLE = new KobleException(
    "No job slots available.",
    "No hay espacios de trabajo disponibles.",
    "BUSINESS_NO_JOB_SLOTS_AVAILABLE"
  );

  static readonly BUSINESS_INVALID_WHY_JOIN_DESCRIPTION = new KobleException(
    'The provided "why join" description is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 1500 characters in length.',
    'La descripción de "por qué unirse" proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 1500 caracteres.',
    "BUSINESS_INVALID_WHY_JOIN_DESCRIPTION"
  );

  static readonly BUSINESS_NO_LIKES_AVAILABLE = new KobleException(
    "This business has no more likes available.",
    "Este negocio no tiene más likes disponibles.",
    "BUSINESS_NO_LIKES_AVAILABLE"
  );

  static readonly BUSINESS_NO_SUPER_LIKES_AVAILABLE = new KobleException(
    "This business has no more super likes available.",
    "Este negocio no tiene más super likes disponibles.",
    "BUSINESS_NO_SUPER_LIKES_AVAILABLE"
  );

  static readonly BUSINESS_USER_RECRUITER_NOT_COMPLETED = new KobleException(
    "The user recruiter must be completed to create a business.",
    "El usuario reclutador debe estar completado para crear un negocio.",
    "BUSINESS_USER_RECRUITER_NOT_COMPLETED"
  );

  static readonly BUSINESS_PROFILE_INCOMPLETE = new KobleException(
    "The business profile must be completed to create a job.",
    "El perfil de negocio debe estar completado para crear un trabajo.",
    "BUSINESS_PROFILE_INCOMPLETE"
  );

  static readonly BUSINESS_PRICING_ID_NOT_FOUND = new KobleException(
    "The provided business pricing ID was not found.",
    "El identificador de precios de negocio proporcionado no fue encontrado.",
    "BUSINESS_PRICING_ID_NOT_FOUND"
  );

  static readonly BUSINESS_PRODUCT_RESTRICTED_COMBINATION = new KobleException(
    "The provided Stripe product is restricted with another product that the user already owns.",
    "El producto de Stripe proporcionado está restringido por otro producto que el usuario ya posee.",
    "BUSINESS_PRODUCT_RESTRICTED_COMBINATION"
  );

  static readonly BUSINESS_SIZE_ID_NOT_FOUND = new KobleException(
    "The provided business size ID doesn't exist.",
    "El identificador de tamaño de negocio proporcionado no existe.",
    "BUSINESS_SIZE_ID_NOT_FOUND"
  );

  static readonly CAREER_ID_NOT_FOUND = new KobleException(
    "The provided career ID could not be found.",
    "El identificador de carrera proporcionado no pudo ser encontrado.",
    "CAREER_ID_NOT_FOUND"
  );

  static readonly CERTIFICATION_ID_NOT_FOUND = new KobleException(
    "The provided certification ID was not found",
    "El ID de la certificación proporcionado no fue encontrado",
    "CERTIFICATION_ID_NOT_FOUND"
  );

  static readonly CERTIFICATION_INVALID_START_DATE = new KobleException(
    "The provided certification start date is invalid. It must be a valid epoch date. It must be prior to the end date and must be in the past.",
    "La fecha de inicio de la certificación proporcionada no es válida. Debe ser una fecha de época válida. Debe ser anterior a la fecha de finalización y debe estar en el pasado.",
    "CERTIFICATION_INVALID_START_DATE"
  );

  static readonly CERTIFICATION_INVALID_END_DATE = new KobleException(
    "The provided certification end date is invalid. It must be a valid epoch date. It must be greater than the start date.",
    "La fecha de finalización de la certificación proporcionada no es válida. Debe ser una fecha de época válida. Debe ser posterior a la fecha de inicio.",
    "CERTIFICATION_INVALID_END_DATE"
  );

  static readonly CERTIFICATION_INVALID_INSTITUTION_NAME = new KobleException(
    "The provided institution name is invalid. It must only contain alphanumeric characters, spaces, digits, hyphens, and should be between 1 and 100 characters",
    "El nombre de la institución proporcionado no es válido. Debe contener solo caracteres alfanuméricos, espacios, dígitos, guiones, y debe tener entre 1 y 100 caracteres",
    "CERTIFICATION_INVALID_INSTITUTION_NAME"
  );

  static readonly CERTIFICATION_INVALID_TITLE = new KobleException(
    "The provided certification title is invalid. It must only contain alphanumeric characters, spaces, digits, hyphens, and should be between 1 and 100 characters",
    "El título de la certificación proporcionado no es válido. Debe contener solo caracteres alfanuméricos, espacios, dígitos, guiones, y debe tener entre 1 y 100 caracteres",
    "CERTIFICATION_INVALID_TITLE"
  );

  static readonly CERTIFICATION_INVALID_ABOUT = new KobleException(
    "The provided certification about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, it should be between 1 and 500 characters, and no more than 20 lines",
    "La descripción de la certificación proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, debe tener entre 1 y 500 caracteres, y no más de 20 líneas",
    "CERTIFICATION_INVALID_ABOUT"
  );

  static readonly CERTIFICATION_INVALID_KEY_COMPETENCIES_LIST =
    new KobleException(
      "The provided key competencies list is invalid. It must contain between 1 and 5 elements.",
      "La lista de competencias clave proporcionada es inválida. Debe contener entre 1 y 5 elementos.",
      "CERTIFICATION_INVALID_KEY_COMPETENCIES_LIST"
    );

  static readonly CHAT_ID_NOT_FOUND = new KobleException(
    "The provided chat ID was not found.",
    "El identificador de chat proporcionado no fue encontrado.",
    "CHAT_ID_NOT_FOUND"
  );

  static readonly CHAT_IS_DISABLED = new KobleException(
    "The provided chat is disabled.",
    "El chat proporcionado está deshabilitado.",
    "CHAT_IS_DISABLED"
  );

  static readonly CHAT_UNAUTHORIZED_UPDATE = new KobleException(
    "The user is not authorized to update this chat.",
    "El usuario no está autorizado para actualizar este chat.",
    "CHAT_UNAUTHORIZED_UPDATE"
  );

  static readonly CHAT_MESSAGE_ID_NOT_FOUND = new KobleException(
    "The provided chat message ID was not found.",
    "El identificador de mensaje de chat proporcionado no fue encontrado.",
    "CHAT_MESSAGE_ID_NOT_FOUND"
  );

  static readonly CHAT_MESSAGE_INVALID_MESSAGE_FORMAT = new KobleException(
    "Invalid message format. The message can't contain more than 20 line breaks and it must have a maximum length of 2048 characters.",
    "Formato de mensaje inválido. El mensaje no puede contener más de 20 saltos de línea y debe tener una longitud máxima de 2048 caracteres.",
    "CHAT_MESSAGE_INVALID_MESSAGE_FORMAT"
  );

  static readonly FORBIDDEN = new KobleException(
    "You are not authorized to perform this action.",
    "No tienes autorización para realizar esta acción.",
    "FORBIDDEN"
  );

  static readonly NOT_IMPLEMENTED = new KobleException(
    "Method not implemented.",
    "Método no implementado.",
    "NOT_IMPLEMENTED"
  );

  static readonly COORDINATE_LATITUDE_OUT_OF_RANGE = new KobleException(
    "The provided latitude is out of range. Latitude must be between -90 and 90 degrees.",
    "La latitud proporcionada está fuera de rango. La latitud debe estar entre -90 y 90 grados.",
    "COORDINATE_LATITUDE_OUT_OF_RANGE"
  );

  static readonly COORDINATE_LONGITUDE_OUT_OF_RANGE = new KobleException(
    "The provided longitude is out of range. Longitude must be between -180 and 180 degrees.",
    "La longitud proporcionada está fuera de rango. La longitud debe estar entre -180 y 180 grados.",
    "COORDINATE_LONGITUDE_OUT_OF_RANGE"
  );

  static readonly CURRENCY_ID_NOT_FOUND = new KobleException(
    "The provided currency ID could not be found.",
    "El identificador de la divisa proporcionado no pudo ser encontrado.",
    "CURRENCY_ID_NOT_FOUND"
  );

  static readonly EDUCATION_ID_NOT_FOUND = new KobleException(
    "The provided education ID could not be found.",
    "El identificador de la educación proporcionada no pudo ser encontrado.",
    "EDUCATION_ID_NOT_FOUND"
  );

  static readonly EDUCATION_INVALID_START_DATE = new KobleException(
    "The provided education start date is invalid. It must be in the past.",
    "La fecha de inicio de la educación proporcionada no es válida. Debe ser en el pasado.",
    "EDUCATION_INVALID_START_DATE"
  );

  static readonly EDUCATION_INVALID_END_DATE = new KobleException(
    "The provided education end date is invalid. It must be after the education start date.",
    "La fecha de fin de la educación proporcionada no es válida. Debe ser después a la fecha de inicio de la educación.",
    "EDUCATION_INVALID_END_DATE"
  );

  static readonly EDUCATION_INVALID_ABOUT = new KobleException(
    "The provided education about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 1500 characters in length.",
    "La descripción de la educación proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 1500 caracteres.",
    "EDUCATION_INVALID_ABOUT"
  );

  static readonly EDUCATION_INVALID_KEY_COMPETENCIES_LIST = new KobleException(
    "The provided key competencies list is invalid. It must contain between 1 and 5 elements.",
    "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 5 elementos.",
    "EDUCATION_INVALID_KEY_COMPETENCIES_LIST"
  );

  static readonly EXPECTATION_ID_NOT_FOUND = new KobleException(
    "The provided expectation ID could not be found",
    "El identificador de expectativa proporcionado no pudo ser encontrado",
    "EXPECTATION_ID_NOT_FOUND"
  );

  static readonly EXTRA_CURRICULAR_ACTIVITY_ID_NOT_FOUND = new KobleException(
    "The provided extracurricular activity ID could not be found.",
    "El identificador de la actividad extracurricular proporcionado no pudo ser encontrado.",
    "EXTRA_CURRICULAR_ACTIVITY_ID_NOT_FOUND"
  );

  static readonly EXTRA_CURRICULAR_ACTIVITY_INVALID_START_DATE =
    new KobleException(
      "The provided extracurricular activity start date is invalid. It must be in the past.",
      "La fecha de inicio de la actividad extracurricular proporcionada no es válida. Debe ser en el pasado.",
      "EXTRA_CURRICULAR_ACTIVITY_INVALID_START_DATE"
    );

  static readonly EXTRA_CURRICULAR_ACTIVITY_INVALID_END_DATE =
    new KobleException(
      "The provided extracurricular activity end date is invalid. It must be in the past.",
      "La fecha de fin de la actividad extracurricular proporcionada no es válida. Debe ser en el pasado.",
      "EXTRA_CURRICULAR_ACTIVITY_INVALID_END_DATE"
    );

  static readonly EXTRA_CURRICULAR_ACTIVITY_INVALID_TITLE = new KobleException(
    "The provided extracurricular activity title is invalid. It must only contain alphanumeric characters, spaces, numbers, hyphens, and should be between 1 and 100 characters",
    "El título de la actividad extracurricular proporcionado no es válido. Sólamente puede contener caracteres alfanuméricos, espacios, números, guiones y debe tener entre 1 y 100 caracteres.",
    "EXTRA_CURRICULAR_ACTIVITY_INVALID_TITLE"
  );

  static readonly EXTRA_CURRICULAR_ACTIVITY_INVALID_SUB_TITLE =
    new KobleException(
      "The provided extracurricular activity sub title is invalid. It must only contain alphanumeric characters, spaces, numbers, hyphens, and should be between 1 and 100 characters",
      "El subtítulo de la actividad extracurricular proporcionado no es válido. Sólamente puede contener caracteres alfanuméricos, espacios, números, guiones y debe tener entre 1 y 100 caracteres.",
      "EXTRA_CURRICULAR_ACTIVITY_INVALID_SUB_TITLE"
    );

  static readonly EXTRA_CURRICULAR_ACTIVITY_INVALID_ABOUT = new KobleException(
    "The provided extracurricular activity about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, it should be between 1 and 500 characters, and no more than 20 lines",
    "La descripción de la actividad extracurricular proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, debe tener entre 1 y 500 caracteres, y no más de 20 líneas",
    "EXTRA_CURRICULAR_ACTIVITY_INVALID_ABOUT"
  );

  static readonly EXTRA_CURRICULAR_ACTIVITY_INVALID_KEY_COMPETENCY_LIST =
    new KobleException(
      "The provided key competency list is invalid. It must contain between 1 and 5 elements.",
      "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 5 elementos.",
      "EXTRA_CURRICULAR_ACTIVITY_INVALID_KEY_COMPETENCY_LIST"
    );

  static readonly GENDER_ID_NOT_FOUND = new KobleException(
    "The provided gender ID could not be found.",
    "El identificador de género proporcionado no pudo ser encontrado.",
    "GENDER_ID_NOT_FOUND"
  );

  static readonly HOBBY_ID_NOT_FOUND = new KobleException(
    "The provided hobby ID could not be found.",
    "El identificador del hobby proporcionado no pudo ser encontrado.",
    "HOBBY_ID_NOT_FOUND"
  );

  static readonly IDENTITY_EMAIL_NOT_FOUND = new KobleException(
    "There is no student or recruiter user account associated with the provided email.",
    "No hay una cuenta de usuario estudiante o reclutador asociada con el correo electrónico proporcionado.",
    "IDENTITY_EMAIL_NOT_FOUND"
  );

  static readonly IDENTITY_CLIENT_NOT_FOUND = new KobleException(
    "The provided identity client could not be found.",
    "El cliente de identidad proporcionado no pudo ser encontrado.",
    "IDENTITY_CLIENT_NOT_FOUND"
  );

  static readonly IDENTITY_REFRESH_TOKEN_NOT_FOUND = new KobleException(
    "The provided refresh token could not be found.",
    "El token de actualización proporcionado no pudo ser encontrado.",
    "IDENTITY_REFRESH_TOKEN_NOT_FOUND"
  );

  static readonly IDENTITY_EMAIL_AND_PASSWORD_DONT_MATCH = new KobleException(
    "The provided email and password do not match.",
    "El correo electrónico y la contraseña proporcionados no coinciden.",
    "IDENTITY_EMAIL_AND_PASSWORD_DONT_MATCH"
  );

  static readonly IDENTITY_ACCESS_TOKEN_NOT_FOUND = new KobleException(
    "The provided access token could not be found.",
    "El token de acceso proporcionado no pudo ser encontrado.",
    "IDENTITY_ACCESS_TOKEN_NOT_FOUND"
  );

  static readonly IDENTITY_RESET_PASSWORD_TOKEN_NOT_FOUND = new KobleException(
    "The provided reset password token could not be found.",
    "El token para restablecer la contraseña proporcionado no pudo ser encontrado.",
    "IDENTITY_RESET_PASSWORD_TOKEN_NOT_FOUND"
  );

  static readonly IDENTITY_INVALID_PHONE_VERIFICATION_CODE = new KobleException(
    "The provided phone verification code is invalid",
    "El código de verificación telefónica proporcionado es inválido",
    "IDENTITY_INVALID_PHONE_VERIFICATION_CODE"
  );

  static readonly IDENTITY_USER_TYPE_NOT_FOUND = new KobleException(
    "The provided user type was not found.",
    "El tipo de usuario proporcionado no fue encontrado.",
    "IDENTITY_USER_TYPE_NOT_FOUND"
  );

  static readonly IDENTITY_INVALID_REFERRAL_CODE_FORMAT = new KobleException(
    "The provided referral code is in an invalid format. It must contain only alphanumeric characters and its length must be between 1 and 32 characters.",
    "El código de referencia proporcionado está en un formato inválido. Debe contener solo caracteres alfanuméricos y su longitud debe estar entre 1 y 32 caracteres.",
    "IDENTITY_INVALID_REFERRAL_CODE_FORMAT"
  );

  static readonly IDENTITY_BACK_OFFICE_IP_NOT_WHITELISTED = new KobleException(
    "The IP address of the request is not whitelisted.",
    "La dirección IP de la solicitud no está en la lista blanca.",
    "IDENTITY_BACK_OFFICE_IP_NOT_WHITELISTED"
  );

  static readonly INDUSTRY_ID_NOT_FOUND = new KobleException(
    "The provided industry ID could not be found.",
    "El identificador de industria proporcionado no pudo ser encontrado.",
    "INDUSTRY_ID_NOT_FOUND"
  );

  static readonly INT_INTERVAL_MIN_MUST_BE_LESS_THAN_MAX = new KobleException(
    "The provided minimum value must be less than or equal to the maximum",
    "El valor mínimo proporcionado debe ser menor o igual al máximo",
    "INT_INTERVAL_MIN_MUST_BE_LESS_THAN_MAX"
  );

  static readonly JOB_CATEGORY_ID_NOT_FOUND = new KobleException(
    "The provided job category ID could not be found.",
    "El identificador de categoría de empleo proporcionado no pudo ser encontrado.",
    "JOB_CATEGORY_ID_NOT_FOUND"
  );

  static readonly JOB_ID_NOT_FOUND = new KobleException(
    "The provided job ID doesn't exist.",
    "El identificador de empleo proporcionado no existe.",
    "JOB_ID_NOT_FOUND"
  );

  static readonly JOB_INVALID_JOB_TITLE = new KobleException(
    "The provided job title is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, and it should be between 2 and 64 characters in length.",
    "El título del empleo proporcionado no es válido. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios, y debe tener entre 2 y 64 caracteres.",
    "JOB_INVALID_JOB_TITLE"
  );

  static readonly JOB_INVALID_ABOUT = new KobleException(
    "The provided job about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 2500 characters in length.",
    "La descripción del empleo proporcionado no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 2500 caracteres.",
    "JOB_INVALID_ABOUT"
  );

  static readonly JOB_INVALID_INFO = new KobleException(
    "The provided job info is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 2500 characters in length.",
    "La información de la vacante proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 2500 caracteres.",
    "JOB_INVALID_INFO"
  );

  static readonly JOB_INVALID_SALARY_RANGE = new KobleException(
    "The provided minimum salary is invalid. It must be less than the maximum salary.",
    "El rango de salario mínimo proporcionado no es válido. Debe ser menor que el salario máximo.",
    "JOB_INVALID_SALARY_RANGE"
  );

  static readonly JOB_INVALID_KEY_COMPETENCY_LIST = new KobleException(
    "The provided key competency list is invalid. It must contain between 1 and 5 elements.",
    "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 5 elementos.",
    "JOB_INVALID_KEY_COMPETENCY_LIST"
  );

  static readonly JOB_INVALID_WORK_MODE_LIST = new KobleException(
    "The provided work mode list is invalid. It must contain exactly 1 element.",
    "La lista de modos de trabajo proporcionada no es válida. Debe contener exáctamente 1 elemento.",
    "JOB_INVALID_WORK_MODE_LIST"
  );

  static readonly JOB_INVALID_WORK_CONTRACT_TYPE_LIST = new KobleException(
    "The provided work contract type list is invalid. It must contain between 1 and 2 elements.",
    "La lista de tipos de contrato de trabajo proporcionada no es válida. Debe contener entre 1 y 2 elementos.",
    "JOB_INVALID_WORK_CONTRACT_TYPE_LIST"
  );

  static readonly JOB_INVALID_WORK_SCHEDULE_TYPE_LIST = new KobleException(
    "The provided work schedule type list is invalid. It must contain exactly 1 element.",
    "La lista de tipos de horario laboral proporcionada no es válida. Debe contener exáctamente 1 elemento.",
    "JOB_INVALID_WORK_SCHEDULE_TYPE_LIST"
  );

  static readonly JOB_INVALID_BENEFIT_LIST = new KobleException(
    "The provided benefit list is invalid. It must contain between 1 and 6 elements.",
    "La lista de beneficios proporcionada no es válida. Debe contener entre 1 y 6 elementos.",
    "JOB_INVALID_BENEFIT_LIST"
  );

  static readonly JOB_INVALID_CAREER_LIST = new KobleException(
    "The provided career list is invalid. It must contain between 1 and 3 elements.",
    "La lista de profesiones proporcionada no es válida. Debe contener entre 1 y 3 elementos.",
    "JOB_INVALID_CAREER_LIST"
  );

  static readonly JOB_INVALID_LANGUAGE_LIST = new KobleException(
    "The provided language list is invalid. It must contain at most 5 elements.",
    "La lista de idiomas proporcionada no es válida. Debe contener máximo 5 elementos.",
    "JOB_INVALID_LANGUAGE_LIST"
  );

  static readonly JOB_INVALID_SPECIAL_REQUIREMENT_LIST = new KobleException(
    "The provided special requirement list is invalid. It must contain maximum 6 elements.",
    "La lista de requisitos especiales proporcionada no es válida. Debe contener máximo 6 elementos.",
    "JOB_INVALID_SPECIAL_REQUIREMENT_LIST"
  );

  static readonly JOB_LANGUAGE_ID_ALREADY_EXIST = new KobleException(
    "The provided language id already exist in the list.",
    "El identificador de idioma proporcionado ya existe en la lista.",
    "JOB_LANGUAGE_ID_ALREADY_EXIST"
  );

  static readonly JOB_IS_NOT_SLOTTED = new KobleException(
    "The provided job is not slotted.",
    "El empleo proporcionado no está en un slot.",
    "JOB_IS_NOT_SLOTTED"
  );

  static readonly JOB_BACKGROUND_IMAGE_SIZE_LIMIT_EXCEEDED = new KobleException(
    "The provided background image size is too large. It must be less than 20MB.",
    "El tamaño de la imagen de fondo proporcionada es demasiado grande. Debe ser menor que 20MB.",
    "JOB_BACKGROUND_IMAGE_SIZE_LIMIT_EXCEEDED"
  );

  static readonly JOB_INVALID_BACKGROUND_IMAGE = new KobleException(
    "The provided background image is invalid. It must be a JPG, JPEG, PNG, or GIF file.",
    "La imagen de fondo proporcionada no es válida. Debe ser un archivo JPG, JPEG, PNG o GIF.",
    "JOB_INVALID_BACKGROUND_IMAGE"
  );

  static readonly JOB_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "You have exceeded the daily limit of background picture uploads",
      "Has superado el límite diario de subidas de imágenes de fondo",
      "JOB_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT_EXCEEDED"
    );

  static readonly JOB_INVALID_PLACE_ID = new KobleException(
    "The provided place ID is invalid. It must be a valid Google place ID and not be null.",
    "El identificador de lugar proporcionado no es válido. Debe ser un identificador de lugar de Google válido y no nulo.",
    "JOB_INVALID_PLACE_ID"
  );

  static readonly JOB_IS_SLOTTED_CANNOT_BE_UPDATED = new KobleException(
    "The job is slotted, cannot be updated.",
    "El empleo está en un slot, no se puede actualizar.",
    "JOB_IS_SLOTTED_CANNOT_BE_UPDATED"
  );

  static readonly JOB_IS_NOT_COMPLETE = new KobleException(
    "The provided job is not complete.",
    "El empleo no está completo.",
    "JOB_IS_NOT_COMPLETE"
  );

  static readonly JOB_INVALID_SKILL_LIST = new KobleException(
    "The provided skill list is invalid. It must contain between 1 and 6 elements.",
    "La lista de habilidades proporcionada no es válida. Debe contener entre 1 y 6 elementos.",
    "JOB_INVALID_SKILL_LIST"
  );

  static readonly JOB_SLOT_REMOVAL_REASON_ID_NOT_FOUND = new KobleException(
    "Job slot removal reason id not found",
    "La razón por la que se está eliminando el trabajo del espacio no se encontró",
    "JOB_SLOT_REMOVAL_REASON_ID_NOT_FOUND"
  );

  static readonly KEY_COMPETENCY_ID_NOT_FOUND = new KobleException(
    "The provided key competency ID doesn't exist.",
    "El identificador de competencia clave proporcionado no existe.",
    "KEY_COMPETENCY_ID_NOT_FOUND"
  );

  static readonly LANGUAGE_ID_NOT_FOUND = new KobleException(
    "The provided language ID could not be found.",
    "El identificador del idioma proporcionado no pudo ser encontrado.",
    "LANGUAGE_ID_NOT_FOUND"
  );

  static readonly LANGUAGE_LEVEL_ID_NOT_FOUND = new KobleException(
    "The provided language level ID could not be found.",
    "El identificador del nivel de idioma proporcionado no pudo ser encontrado.",
    "LANGUAGE_LEVEL_ID_NOT_FOUND"
  );

  static readonly MAILING_SUBSCRIBER_EMAIL_TAKEN = new KobleException(
    "The provided email is already taken by another mailing subscriber.",
    "El correo electrónico proporcionado ya está en uso por otro suscriptor de correo.",
    "MAILING_SUBSCRIBER_EMAIL_TAKEN"
  );

  static readonly MATCH_ID_NOT_FOUND = new KobleException(
    "The provided match ID was not found",
    "El ID del match proporcionado no se encontró",
    "MATCH_ID_NOT_FOUND"
  );

  static readonly MATCH_ALREADY_ACTIVE = new KobleException(
    "There is already an active match between the student and the job offer.",
    "Ya existe un match activo para el estudiante y la vacante.",
    "MATCH_ALREADY_ACTIVE"
  );

  static readonly COMPATIBILITY_SCORE_ID_NOT_FOUND = new KobleException(
    "The provided compatibility score id could not be found.",
    "El identificador del score de compatibilidad proporcionado no pudo ser encontrado.",
    "COMPATIBILITY_SCORE_ID_NOT_FOUND"
  );

  static readonly COMPATIBILITY_SCORE_INVALID_SCORE = new KobleException(
    "The provided score is invalid. It must be between 0 and 100.",
    "El valor del compatibilidad proporcionado no es válido. Debe estar entre 0 y 100.",
    "COMPATIBILITY_SCORE_INVALID_SCORE"
  );

  static readonly COMPATIBILITY_SCORE_ALREADY_PROCESSED = new KobleException(
    "The compatibility score has already been processed.",
    "El score de compatibilidad ya ha sido procesado.",
    "COMPATIBILITY_SCORE_ALREADY_PROCESSED"
  );

  static readonly COMPATIBILITY_SCORE_BUSINESS_NOT_PREMIUM = new KobleException(
    "The business must be premium to create the compatibility score.",
    "La empresa debe ser premium para crear el score de compatibilidad.",
    "COMPATIBILITY_SCORE_BUSINESS_NOT_PREMIUM"
  );

  static readonly COMPATIBILITY_SCORE_ALREADY_EXISTS = new KobleException(
    "The compatibility score already exists.",
    "El score de compatibilidad ya existe.",
    "COMPATIBILITY_SCORE_ALREADY_EXISTS"
  );

  static readonly MATCH_STATUS_CHANGE_REASON_ID_NOT_FOUND = new KobleException(
    "The provided match status change reason ID was not found",
    "El ID de la razón de cambio de estado del match proporcionado no se encontró",
    "MATCH_STATUS_CHANGE_REASON_ID_NOT_FOUND"
  );

  static readonly MATCH_STATUS_CHANGE_REASON_INVALID_MATCH_STATUS_ID =
    new KobleException(
      "The provided match status change reason ID is not valid for the provided match status ID",
      "El ID de la razón de cambio de estado del match proporcionado no es válido para el ID de estado del match proporcionado",
      "MATCH_STATUS_CHANGE_REASON_INVALID_MATCH_STATUS_ID"
    );

  static readonly MATCH_STATUS_CHANGE_REASON_ID_REQUIRED = new KobleException(
    "The match status change reason ID is required",
    "El ID de la razón de cambio de estado del match es requerido",
    "MATCH_STATUS_CHANGE_REASON_ID_REQUIRED"
  );

  static readonly MATCH_STATUS_CHANGE_REASON_INVALID_DESCRIPTION =
    new KobleException(
      "The description provided is invalid, it must be a string between 5 and 100 characters, and have less than or equal to 5 newlines",
      "La descripción proporcionada es inválida, debe ser una cadena entre 5 y 100 caracteres, y tener menos o igual a 5 saltos de línea",
      "MATCH_STATUS_CHANGE_REASON_INVALID_DESCRIPTION"
    );

  static readonly MATCH_STATUS_CHANGE_REASON_DESCRIPTION_REQUIRED =
    new KobleException(
      "The description is required for the provided match status change reason ID",
      "La descripción es requerida para el ID de la razón de cambio de estado del match proporcionado",
      "MATCH_STATUS_CHANGE_REASON_DESCRIPTION_REQUIRED"
    );

  static readonly MATCH_STATUS_CHANGE_REASON_DESCRIPTION_NOT_REQUIRED =
    new KobleException(
      "The description is not required for the provided match status change reason ID",
      "La descripción no es requerida para el ID de la razón de cambio de estado del match proporcionado",
      "MATCH_STATUS_CHANGE_REASON_DESCRIPTION_NOT_REQUIRED"
    );

  static readonly MATCH_STATUS_ID_NOT_FOUND = new KobleException(
    "The provided match status ID was not found",
    "El ID del estado del match proporcionado no se encontró",
    "MATCH_STATUS_ID_NOT_FOUND"
  );

  static readonly UNAUTHORIZED_MATCH_STATUS_UPDATE = new KobleException(
    "The match status id is not authorized to be updated",
    "El ID del estado del match no está autorizado para ser actualizado",
    "UNAUTHORIZED_MATCH_STATUS_UPDATE"
  );

  static readonly NEW_CAREER_REQUEST_NAME_INVALID = new KobleException(
    "The new career request name is invalid. It must be between 2 and 100 characters, and contain only alphanumeric characters, diacritics or punctuation.",
    "El nombre de la nueva solicitud de carrera es inválido. Debe tener entre 2 y 100 caracteres, y contener solo caracteres alfanuméricos, diacríticos o de puntuación.",
    "NEW_CAREER_REQUEST_NAME_INVALID"
  );

  static readonly NEW_EMAIL_VERIFICATION_PIN_NOT_FOUND = new KobleException(
    "The provided update email pin could not be found.",
    "El identificador de cambio de correo electrónico proporcionado no se pudo encontrar.",
    "NEW_EMAIL_VERIFICATION_PIN_NOT_FOUND"
  );

  static readonly NEW_EMAIL_VERIFICATION_PIN_DAILY_LIMIT = new KobleException(
    "You have reached the daily limit of update email pins.",
    "Has alcanzado el límite diario de cambios de correo electrónico.",
    "NEW_EMAIL_VERIFICATION_PIN_DAILY_LIMIT"
  );

  static readonly NEW_EMAIL_VERIFICATION_PIN_INVALID_EMAIL = new KobleException(
    "The provided email is invalid. It must be a valid email address, between 5 and 64 characters.",
    "El correo electrónico proporcionado es inválido. Debe ser una dirección de correo electrónico válida, entre 5 y 64 caracteres.",
    "NEW_EMAIL_VERIFICATION_PIN_INVALID_EMAIL"
  );

  static readonly NEW_EMAIL_VERIFICATION_PIN_WRONG_CURRENT_PASSWORD =
    new KobleException(
      "The provided current password is incorrect.",
      "La contraseña actual proporcionada es incorrecta.",
      "NEW_EMAIL_VERIFICATION_PIN_WRONG_CURRENT_PASSWORD"
    );

  static readonly NEW_EMAIL_VERIFICATION_PIN_EXPIRED = new KobleException(
    "The provided pin has expired. Please generate a new one.",
    "El identificador proporcionado ha expirado. Por favor, genera uno nuevo.",
    "NEW_EMAIL_VERIFICATION_PIN_EXPIRED"
  );

  static readonly NEW_EMAIL_VERIFICATION_PIN_INVALID = new KobleException(
    "The provided pin is invalid. It must be a 6-digit number.",
    "El identificador proporcionado es inválido. Debe ser un número de 6 dígitos.",
    "NEW_EMAIL_VERIFICATION_PIN_INVALID"
  );

  static readonly NEW_UNIVERSITY_REQUEST_NAME_INVALID = new KobleException(
    "The new university request name is invalid. It must be between 2 and 100 characters, and contain only alphanumeric characters, diacritics or punctuation.",
    "El nombre de la nueva solicitud de universidad es inválido. Debe tener entre 2 y 100 caracteres, y contener solo caracteres alfanuméricos, diacríticos o de puntuación.",
    "NEW_UNIVERSITY_REQUEST_NAME_INVALID"
  );

  static readonly USER_EMAIL_NOTIFICATION_PREFERENCE_INVALID_USER_EMAIL_NOTIFICATION_PREFERENCE =
    new KobleException(
      "The provided user email notification preference is invalid.",
      "La preferencia de notificación por correo electrónico proporcionada no es válida.",
      "USER_EMAIL_NOTIFICATION_PREFERENCE_INVALID_USER_EMAIL_NOTIFICATION_PREFERENCE"
    );

  static readonly USER_EMAIL_NOTIFICATION_PREFERENCE_TOKEN_NOT_FOUND =
    new KobleException(
      "The provided user email notification preference token was not found.",
      "El token de preferencia de notificación por correo electrónico de usuario proporcionado no fue encontrado.",
      "USER_EMAIL_NOTIFICATION_PREFERENCE_TOKEN_NOT_FOUND"
    );

  static readonly NOTIFICATION_ID_NOT_FOUND = new KobleException(
    "The provided notification ID could not be found.",
    "El identificador de notificación proporcionado no pudo ser encontrado.",
    "NOTIFICATION_ID_NOT_FOUND"
  );

  static readonly NOTIFICATION_UNAUTHORIZED_UPDATE_ATTEMPT = new KobleException(
    "You are not authorized to update this notification.",
    "No estás autorizado para actualizar esta notificación.",
    "NOTIFICATION_UNAUTHORIZED_UPDATE_ATTEMPT"
  );

  static readonly COMMON_INVALID_LIMIT = new KobleException(
    "The provided limit must be greater than or equal to 0",
    "El límite proporcionado debe ser mayor o igual a 0",
    "COMMON_INVALID_LIMIT"
  );

  static readonly COMMON_INVALID_OFFSET = new KobleException(
    "The provided offset must be greater than or equal to 0",
    "El desplazamiento proporcionado debe ser mayor o igual a 0",
    "COMMON_INVALID_OFFSET"
  );

  static readonly PHONE_COUNTRY_CODE_NOT_FOUND = new KobleException(
    "The provided phone country code could not be found.",
    "El código de país del celular proporcionado no pudo ser encontrado.",
    "PHONE_COUNTRY_CODE_NOT_FOUND"
  );

  static readonly PHONE_ALREADY_EXISTS = new KobleException(
    "The provided phone number is already taken.",
    "El celular proporcionado ya ha sido tomado por otro usuario.",
    "PHONE_ALREADY_EXISTS"
  );

  static readonly PHONE_INVALID_PHONE_NUMBER = new KobleException(
    "The provided phone number is invalid. It must consist of exactly 10 digits.",
    "El celular proporcionado no es válido. Sólamente debe contener 10 dígitos.",
    "PHONE_INVALID_PHONE_NUMBER"
  );

  static readonly PRONOUN_ID_NOT_FOUND = new KobleException(
    "The provided pronoun ID could not be found.",
    "El identificador del pronombre proporcionado no pudo ser encontrado.",
    "PRONOUN_ID_NOT_FOUND"
  );

  static readonly SALARY_RANGE_ID_NOT_FOUND = new KobleException(
    "The provided salary range ID could not be found.",
    "El identificador del rango salarial proporcionado no pudo ser encontrado.",
    "SALARY_RANGE_ID_NOT_FOUND"
  );

  static readonly SKILL_ID_NOT_FOUND = new KobleException(
    "The provided skill identifier was not found.",
    "El identificador de la habilidad proporcionado no se pudo encontrar.",
    "SKILL_ID_NOT_FOUND"
  );

  static readonly SOCIAL_MEDIA_ID_NOT_FOUND = new KobleException(
    "The provided social media ID could not be found.",
    "El identificador de la red social proporcionada no pudo ser encontrado.",
    "SOCIAL_MEDIA_ID_NOT_FOUND"
  );

  static readonly SOCIAL_MEDIA_URL_INVALID_URL = new KobleException(
    "The provided social media URL is invalid. It must be a well-formed, absolute URL (including protocol) of 256 characters at most.",
    "El URL de la red social proporcionada no es válido. Debe ser un URL absoluto, bien formada (incluyendo el protocolo) y de un máximo de 256 caracteres.",
    "SOCIAL_MEDIA_URL_INVALID_URL"
  );

  static readonly SORT_BY_INVALID_SORT_ORDER = new KobleException(
    "The provided sort order is invalid. It must be either 'ASC' or 'DESC'.",
    "El orden de clasificación proporcionado no es válido. Debe ser 'ASC' o 'DESC'.",
    "SORT_BY_INVALID_SORT_ORDER"
  );

  static readonly SPECIAL_REQUIREMENT_ID_NOT_FOUND = new KobleException(
    "The provided special requirement ID could not be found.",
    "El identificador del requisito especial proporcionado no pudo ser encontrado.",
    "SPECIAL_REQUIREMENT_ID_NOT_FOUND"
  );

  static readonly TEXT_GENERATOR_SERVICE_REFINE_TEXT_INVALID_TEXT =
    new KobleException(
      "The provided text for refining is invalid. It should be between 140 and 2500 characters.",
      "El texto proporcionado para refinar no es válido. Debe tener entre 140 y 2500 caracteres.",
      "TEXT_GENERATOR_SERVICE_REFINE_TEXT_INVALID_TEXT"
    );

  static readonly TEXT_GENERATOR_SERVICE_RESUME_THIRD_PARTY_JOB_ABOUT_INVALID_TEXT =
    new KobleException(
      "The provided text for resuming is invalid. It should be between 140 and 2500 characters.",
      "El texto proporcionado para resumir no es válido. Debe tener entre 140 y 2500 caracteres.",
      "TEXT_GENERATOR_SERVICE_RESUME_THIRD_PARTY_JOB_ABOUT_INVALID_TEXT"
    );

  static readonly TEXT_GENERATOR_SERVICE_INVALID_BUSINESS_NAME =
    new KobleException(
      "The provided business name is invalid. It should be between 1 and 128 characters.",
      "El nombre de la empresa proporcionado no es válido. Debe tener entre 1 y 128 caracteres.",
      "TEXT_GENERATOR_SERVICE_INVALID_BUSINESS_NAME"
    );

  static readonly TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_BUSINESS_VISION_REQUIRED =
    new KobleException(
      "The provided business vision is invalid. It should be between 1 and 150 characters.",
      "La visión de la empresa proporcionada no es válida. Debe tener entre 1 y 150 caracteres.",
      "TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_BUSINESS_VISION_REQUIRED"
    );

  static readonly TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_BUSINESS_MISSION_REQUIRED =
    new KobleException(
      "The provided business mission is invalid. It should be less than 150 characters.",
      "La misión de la empresa proporcionada no es válida. Debe tener menos de 150 caracteres.",
      "TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_BUSINESS_MISSION_REQUIRED"
    );

  static readonly TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_SLOGAN_REQUIRED =
    new KobleException(
      "The provided slogan is invalid. It should be less than 150 characters.",
      "El eslogan proporcionado no es válido. Debe tener menos de 150 caracteres.",
      "TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_SLOGAN_REQUIRED"
    );

  static readonly TEXT_GENERATOR_SERVICE_INVALID_JOB_TITLE = new KobleException(
    "The provided job title is invalid. It should be between 2 and 60 characters.",
    "El título del trabajo proporcionado no es válido. Debe tener entre 2 y 60 caracteres.",
    "TEXT_GENERATOR_SERVICE_INVALID_JOB_TITLE"
  );

  static readonly TEXT_GENERATOR_SERVICE_JUNGIAN_ARCHETYPE_ID_NOT_FOUND =
    new KobleException(
      "The provided Jungian archetype ID could not be found.",
      "El identificador de arquetipo de Jung proporcionado no pudo ser encontrado.",
      "TEXT_GENERATOR_SERVICE_JUNGIAN_ARCHETYPE_ID_NOT_FOUND"
    );

  static readonly TEXT_GENERATOR_SERVICE_INVALID_JUNGIAN_ARCHETYPE_LIST =
    new KobleException(
      "The provided Jungian archetype list is invalid. It should have between 1 and 3 items.",
      "La lista de arquetipos de Jung proporcionada no es válida. Debe tener entre 1 y 3 elementos.",
      "TEXT_GENERATOR_SERVICE_INVALID_JUNGIAN_ARCHETYPE_LIST"
    );

  static readonly TEXT_GENERATOR_SERVICE_INVALID_RESPONSIBILITY_LIST =
    new KobleException(
      "The provided responsibilities list is invalid. It should be less than 3 items.",
      "La lista de responsabilidades proporcionada no es válida. Debe tener menos de 3 elementos.",
      "TEXT_GENERATOR_SERVICE_INVALID_RESPONSIBILITY_LIST"
    );

  static readonly THIRD_PARTY_JOB_PLATFORM_ID_NOT_FOUND = new KobleException(
    "The provided third party job platform ID was not found.",
    "El ID de la plataforma de empleo de terceros proporcionado no se encontró.",
    "THIRD_PARTY_JOB_PLATFORM_ID_NOT_FOUND"
  );

  static readonly THIRD_PARTY_JOB_ID_NOT_FOUND = new KobleException(
    "The provided third party job ID could not be found.",
    "El ID de trabajo de tercero proporcionado no se encontró.",
    "THIRD_PARTY_JOB_ID_NOT_FOUND"
  );

  static readonly THIRD_PARTY_JOB_PLATFORM_GIVEN_ID_ALREADY_EXISTS =
    new KobleException(
      "The provided third party job platform given ID already exists.",
      "El ID de trabajo de tercero proporcionado ya existe.",
      "THIRD_PARTY_JOB_PLATFORM_GIVEN_ID_ALREADY_EXISTS"
    );

  static readonly THIRD_PARTY_JOB_INVALID_URL = new KobleException(
    "The provided URL is invalid. It must be a well-formed, absolute URL (including protocol) of 256 characters at most.",
    "La URL proporcionada no es válida, debe ser una URL absoluta bien formada (incluido el protocolo) de 256 caracteres como máximo.",
    "THIRD_PARTY_JOB_INVALID_URL"
  );

  static readonly THIRD_PARTY_JOB_INVALID_PUBLISH_AT = new KobleException(
    "The provided publish at date is invalid, it must be in the last years and not in the future.",
    "La fecha de publicación proporcionada no es válida, debe estar en el último año y no en el futuro.",
    "THIRD_PARTY_JOB_INVALID_PUBLISH_AT"
  );

  static readonly THIRD_PARTY_JOB_INVALID_ABOUT = new KobleException(
    "The provided third party job about is invalid. It should be between 140 and 2500 characters in length.",
    "La descripción del empleo proporcionado no es válida. Debe tener entre 140 y 2500 caracteres.",
    "THIRD_PARTY_JOB_INVALID_ABOUT"
  );

  static readonly THIRD_PARTY_JOB_INVALID_PAGINATION_LIMIT = new KobleException(
    "The provided pagination limit is invalid. It must be between 1 and 36.",
    "El límite de paginación proporcionado no es válido. Debe estar entre 1 y 36.",
    "THIRD_PARTY_JOB_INVALID_PAGINATION_LIMIT"
  );

  static readonly UNIVERSITY_ID_NOT_FOUND = new KobleException(
    "The provided university ID could not be found.",
    "El identificador de la universidad proporcionada no pudo ser encontrado.",
    "UNIVERSITY_ID_NOT_FOUND"
  );

  static readonly USER_RECRUITER_CHAT_ID_NOT_FOUND = new KobleException(
    "The provided recruiter user chat ID was not found.",
    "El identificador del chat del usuario reclutador proporcionado no pudo ser encontrado.",
    "USER_RECRUITER_CHAT_ID_NOT_FOUND"
  );

  static readonly USER_RECRUITER_EMAIL_TAKEN = new KobleException(
    "This email is already taken by a user recruiter account.",
    "Este correo electrónico ya está siendo usado por una cuenta de usuario reclutador.",
    "USER_RECRUITER_EMAIL_TAKEN"
  );

  static readonly USER_RECRUITER_ID_NOT_FOUND = new KobleException(
    "The provided user recruiter ID could not be found.",
    "El identificador del usuario reclutador proporcionado no pudo ser encontrado.",
    "USER_RECRUITER_ID_NOT_FOUND"
  );

  static readonly USER_RECRUITER_PHONE_TAKEN = new KobleException(
    "This phone is already taken by a user recruiter account.",
    "Este teléfono ya está siendo usado por una cuenta de usuario reclutador.",
    "USER_RECRUITER_PHONE_TAKEN"
  );

  static readonly USER_RECRUITER_IS_COMPLETING = new KobleException(
    "The user recruiter is still completing is account.",
    "El usuario reclutador aún está completando su cuenta.",
    "USER_RECRUITER_IS_COMPLETING"
  );

  static readonly USER_RECRUITER_EMAIL_DOESNT_EXIST = new KobleException(
    "This email is not linked to any user recruiter accounts.",
    "Este correo electrónico no está vinculado a ninguna cuenta de usuario reclutador.",
    "USER_RECRUITER_EMAIL_DOESNT_EXIST"
  );

  static readonly USER_RECRUITER_ACCOUNT_ALREADY_COMPLETED = new KobleException(
    "This user recruiter has already completed the account.",
    "El usuario reclutador ya ha completado su cuenta.",
    "USER_RECRUITER_ACCOUNT_ALREADY_COMPLETED"
  );

  static readonly USER_RECRUITER_SEND_ACCOUNT_COMPLETION_EMAIL_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "This user recruiter account has exceeded the daily limit of 3 account completion emails.",
      "La cuenta de usuario reclutador ha excedido el límite diario de 3 correos electrónicos de finalización de cuenta.",
      "USER_RECRUITER_SEND_ACCOUNT_COMPLETION_EMAIL_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_RECRUITER_SEND_RESET_PASSWORD_EMAIL_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "This user recruiter account has exceeded the daily limit of 3 reset password emails.",
      "Esta cuenta de usuario reclutador ha excedido el límite diario de 3 correos electrónicos de restablecimiento de contraseña.",
      "USER_RECRUITER_SEND_RESET_PASSWORD_EMAIL_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_RECRUITER_ACCOUNT_COMPLETED_PASSWORD_NOT_SET =
    new KobleException(
      "The user recruiter account has been created and completed without setting a password.",
      "La cuenta de usuario reclutador ha sido creada y completada sin establecer una contraseña.",
      "USER_RECRUITER_ACCOUNT_COMPLETED_PASSWORD_NOT_SET"
    );

  static readonly USER_RECRUITER_ACCOUNT_COMPLETION_TOKEN_NOT_FOUND =
    new KobleException(
      "The provided user recruiter account completion token could not be found.",
      "El token de finalización de la cuenta proporcionada no pudo ser encontrado.",
      "USER_RECRUITER_ACCOUNT_COMPLETION_TOKEN_NOT_FOUND"
    );

  static readonly USER_RECRUITER_UNAUTHORIZED_BUSINESS_UPDATE_ATTEMPT =
    new KobleException(
      "The user recruiter is not authorized to update this business.",
      "El usuario reclutador no está autorizado para actualizar este negocio.",
      "USER_RECRUITER_UNAUTHORIZED_BUSINESS_UPDATE_ATTEMPT"
    );

  static readonly USER_RECRUITER_INVALID_FIRST_NAME = new KobleException(
    "The provided user recruiter first name is invalid. It must only contain alphabetic characters (with or without diacritics), spaces, apostrophes and hyphens, and be between 2 and 64 characters in length.",
    "El nombre proporcionado del usuario reclutador no es válido. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), espacios, apóstrofos y guiones, y tener entre 2 y 64 caracteres de longitud.",
    "USER_RECRUITER_INVALID_FIRST_NAME"
  );

  static readonly USER_RECRUITER_INVALID_LAST_NAME = new KobleException(
    "The provided user recruiter last name is invalid. It must only contain alphabetic characters (with or without diacritics), spaces, apostrophes and hyphens, and be between 2 and 64 characters in length.",
    "El apellido proporcionado del usuario reclutador no es válido. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), espacios, apóstrofos y guiones, y tener entre 2 y 64 caracteres de longitud.",
    "USER_RECRUITER_INVALID_LAST_NAME"
  );

  static readonly USER_RECRUITER_INVALID_DATE_OF_BIRTH = new KobleException(
    "The provided date of birth is invalid. It must fall within the last 18 to 100 years.",
    "La fecha de nacimiento proporcionada es inválida. Debe estar dentro de los últimos 18 a 100 años.",
    "USER_RECRUITER_INVALID_DATE_OF_BIRTH"
  );

  static readonly USER_RECRUITER_INVALID_ABOUT = new KobleException(
    "The provided user recruiter about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, it should be between 140 and 500 characters in length, and have a maximum of 20 line breaks.",
    "La descripción del usuario reclutador proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, debe tener entre 140 y 500 caracteres, y un máximo de 20 saltos de línea.",
    "USER_RECRUITER_INVALID_ABOUT"
  );

  static readonly USER_RECRUITER_INVALID_PASSWORD = new KobleException(
    "The provided user recruiter password is invalid. It can only contain alphanumeric characters, special characters, and must be between 12 and 64 characters in length.",
    "La contraseña proporcionada del usuario reclutador es inválida. Sólamente puede contener caracteres alfanuméricos, caracteres especiales, y debe tener entre 12 y 64 caracteres.",
    "USER_RECRUITER_INVALID_PASSWORD"
  );

  static readonly USER_RECRUITER_FREE_TRIAL_ALREADY_USED = new KobleException(
    "This user recruiter has already used the free trial.",
    "El usuario reclutador ya ha usado la prueba gratuita.",
    "USER_RECRUITER_FREE_TRIAL_ALREADY_USED"
  );

  static readonly USER_RECRUITER_UNAUTHORIZED_JOB_UPDATE_ATTEMPT =
    new KobleException(
      "The user recruiter is not authorized to update this job.",
      "El usuario reclutador no está autorizado para actualizar este trabajo.",
      "USER_RECRUITER_UNAUTHORIZED_JOB_UPDATE_ATTEMPT"
    );

  static readonly USER_RECRUITER_UNAUTHORIZED_USER_RECRUITER_CHAT_GET_ATTEMPT =
    new KobleException(
      "The user recruiter is not authorized to get this chat.",
      "El usuario reclutador no está autorizado para obtener este chat.",
      "USER_RECRUITER_UNAUTHORIZED_USER_RECRUITER_CHAT_GET_ATTEMPT"
    );

  static readonly USER_RECRUITER_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED =
    new KobleException(
      "The provided profile picture is too large. It must be less than 20MB.",
      "La imagen de perfil proporcionada es demasiado grande. Debe ser menor a 20MB.",
      "USER_RECRUITER_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED"
    );

  static readonly USER_RECRUITER_INVALID_PROFILE_IMAGE = new KobleException(
    "The provided profile picture is invalid. It must be a JPG, JPEG, PNG or GIF file.",
    "La imagen de perfil proporcionada es inválida. Debe ser un archivo JPG, JPEG, PNG o GIF.",
    "USER_RECRUITER_INVALID_PROFILE_IMAGE"
  );

  static readonly USER_RECRUITER_UPDATE_PROFILE_PICTURE_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "You have exceeded the daily limit of profile picture uploads.",
      "Has excedido el límite diario de subidas de imagen de perfil.",
      "USER_RECRUITER_UPDATE_PROFILE_PICTURE_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_RECRUITER_MAX_BUSINESSES_ALLOWED_EXCEEDED =
    new KobleException(
      "The user recruiter has reached the maximum number of businesses allowed.",
      "El usuario reclutador ha alcanzado el número máximo de negocios permitidos.",
      "USER_RECRUITER_MAX_BUSINESSES_ALLOWED_EXCEEDED"
    );

  static readonly USER_RECRUITER_UNAUTHORIZED_BUSINESS = new KobleException(
    "The user recruiter is not authorized to access this business.",
    "El usuario reclutador no está autorizado para acceder a este negocio.",
    "USER_RECRUITER_UNAUTHORIZED_BUSINESS"
  );

  static readonly USER_RECRUITER_UPDATE_PASSWORD_WRONG_CURRENT_PASSWORD =
    new KobleException(
      "The provided current password of the user recruiter is incorrect.",
      "La contraseña actual proporcionada del usuario reclutador es incorrecta.",
      "USER_RECRUITER_UPDATE_PASSWORD_WRONG_CURRENT_PASSWORD"
    );

  static readonly USER_RECRUITER_UPDATE_PHONE_SEND_PIN_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "The user recruiter account has exceeded his daily limit for sending phone pins, 10 per day.",
      "La cuenta de usuario reclutador ha excedido su límite diario para enviar pines de teléfono, 10 por día.",
      "USER_RECRUITER_UPDATE_PHONE_SEND_PIN_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_RECRUITER_UPDATE_PHONE_INVALID_PIN = new KobleException(
    "The provided phone pin is invalid. It must be 6 characters in length.",
    "El pin de teléfono proporcionado es inválido. Debe tener 6 caracteres de longitud.",
    "USER_RECRUITER_UPDATE_PHONE_INVALID_PIN"
  );

  static readonly USER_RECRUITER_UPDATE_PHONE_WRONG_CURRENT_PASSWORD =
    new KobleException(
      "The provided current password of the user recruiter is incorrect.",
      "La contraseña actual proporcionada del usuario reclutador es incorrecta.",
      "USER_RECRUITER_UPDATE_PHONE_WRONG_CURRENT_PASSWORD"
    );

  static readonly USER_RECRUITER_UPDATE_PHONE_TWILIO_VERIFICATION_FAILED =
    new KobleException(
      "The twilio verification has failed.",
      "La verificación de twilio ha fallado.",
      "USER_RECRUITER_UPDATE_PHONE_TWILIO_VERIFICATION_FAILED"
    );

  static readonly USER_RECRUITER_UPDATE_PASSWORD_INVALID_NEW_PASSWORD =
    new KobleException(
      "The provided user recruiter new password is invalid. It must be between 12 and 64 characters in length.",
      "La nueva contraseña proporcionada del usuario reclutador es inválida. Debe tener entre 12 y 64 caracteres de longitud.",
      "USER_RECRUITER_UPDATE_PASSWORD_INVALID_NEW_PASSWORD"
    );

  static readonly USER_RECRUITER_UNAUTHORIZED_MATCH_UPDATE_ATTEMPT =
    new KobleException(
      "The user recruiter is not authorized to update this match.",
      "El usuario reclutador no está autorizado para actualizar este match.",
      "USER_RECRUITER_UNAUTHORIZED_MATCH_UPDATE_ATTEMPT"
    );

  static readonly USER_RECRUITER_UNAUTHORIZED_COMPATIBILITY_SCORE_CREATE_ATTEMPT =
    new KobleException(
      "The user recruiter is not authorized to create this compatibility score.",
      "El usuario reclutador no está autorizado para crear este score de compatibilidad.",
      "USER_RECRUITER_UNAUTHORIZED_COMPATIBILITY_SCORE_CREATE_ATTEMPT"
    );

  static readonly USER_RECRUITER_LIKE_NOT_FOUND = new KobleException(
    "The user recruiter like was not found.",
    "El like del usuario reclutador no fue encontrado.",
    "USER_RECRUITER_LIKE_NOT_FOUND"
  );

  static readonly USER_RECRUITER_LIKE_ALREADY_EXISTS = new KobleException(
    "The user recruiter like already exists.",
    "El like del usuario reclutador ya existe.",
    "USER_RECRUITER_LIKE_ALREADY_EXISTS"
  );

  static readonly USER_RECRUITER_SUPER_LIKE_ALREADY_EXISTS = new KobleException(
    "The user recruiter super like already exists.",
    "El super like del usuario reclutador ya existe.",
    "USER_RECRUITER_SUPER_LIKE_ALREADY_EXISTS"
  );

  static readonly USER_RECRUITER_LIKE_INVALID_MESSAGE = new KobleException(
    'The provided "message" is invalid. It must only contain alphanumeric characters, spaces, permitted punctuation marks (commas, semicolons, periods, hyphens, underscores, parentheses) and should be between 0 and 2048 characters in length.',
    'El "mensaje" proporcionado no es válido. Sólamente puede contener caracteres alfanuméricos, espacios, signos de puntuación permitidos (comas, puntos y comas, puntos, guiones, guiones bajos, paréntesis) y debe tener entre 0 y 2048 caracteres.',
    "USER_RECRUITER_LIKE_INVALID_MESSAGE"
  );

  static readonly USER_STUDENT_CHAT_ID_NOT_FOUND = new KobleException(
    "The provided user student chat identifier was not found.",
    "El identificador del chat de usuario estudiante proporcionado no fue encontrado.",
    "USER_STUDENT_CHAT_ID_NOT_FOUND"
  );

  static readonly USER_STUDENT_EMAIL_TAKEN = new KobleException(
    "The provided email is already taken by a user student account.",
    "El correo electrónico proporcionado ya está en uso por una cuenta de usuario estudiante.",
    "USER_STUDENT_EMAIL_TAKEN"
  );

  static readonly USER_STUDENT_ID_NOT_FOUND = new KobleException(
    "The provided user student ID could not be found.",
    "El identificador de usuario estudiante proporcionado no se pudo encontrar.",
    "USER_STUDENT_ID_NOT_FOUND"
  );

  static readonly USER_STUDENT_INVALID_FIRST_NAME = new KobleException(
    "The provided user student first name is invalid. It must only contain alphabetic characters (with or without diacritics), spaces, apostrophes and hyphens, and be between 2 and 64 characters in length.",
    "El nombre proporcionado del usuario estudiante no es válido. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), espacios, apóstrofos y guiones, y tener entre 2 y 64 caracteres de longitud.",
    "USER_STUDENT_INVALID_FIRST_NAME"
  );

  static readonly USER_STUDENT_INVALID_LAST_NAME = new KobleException(
    "The provided user student last name is invalid. It must only contain alphabetic characters (with or without diacritics), spaces, apostrophes and hyphens, and be between 2 and 64 characters in length.",
    "El apellido proporcionado del usuario estudiante no es válido. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), espacios, apóstrofos y guiones, y tener entre 2 y 64 caracteres de longitud.",
    "USER_STUDENT_INVALID_LAST_NAME"
  );

  static readonly USER_STUDENT_INVALID_DATE_OF_BIRTH = new KobleException(
    "The provided date of birth is invalid. It must fall within the last 16 to 100 years.",
    "La fecha de nacimiento proporcionada es inválida. Debe estar dentro de los últimos 16 a 100 años.",
    "USER_STUDENT_INVALID_DATE_OF_BIRTH"
  );

  static readonly USER_STUDENT_INVALID_ABOUT = new KobleException(
    "The provided user student about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, and it should be between 140 and 1500 characters in length.",
    "La descripción del usuario estudiante proporcionada no es válida. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, y debe tener entre 140 y 1500 caracteres.",
    "USER_STUDENT_INVALID_ABOUT"
  );

  static readonly USER_STUDENT_INVALID_PASSWORD = new KobleException(
    "The provided user student password is invalid. It must be between 12 and 64 characters in length.",
    "La contraseña proporcionada del usuario estudiante no es válida. Debe tener entre 12 y 64 caracteres de longitud.",
    "USER_STUDENT_INVALID_PASSWORD"
  );

  static readonly USER_STUDENT_ACCOUNT_ALREADY_COMPLETED = new KobleException(
    "This user student has already completed the account.",
    "Este usuario estudiante ya ha completado su cuenta.",
    "USER_STUDENT_ACCOUNT_ALREADY_COMPLETED"
  );

  static readonly USER_STUDENT_EMAIL_DOESNT_EXIST = new KobleException(
    "This email is not linked to any user student account.",
    "Este correo electrónico no está vinculado a ninguna cuenta de usuario estudiante.",
    "USER_STUDENT_EMAIL_DOESNT_EXIST"
  );

  static readonly USER_STUDENT_IS_COMPLETING = new KobleException(
    "This user student is still completing the account.",
    "El usuario estudiante aún está completando su cuenta.",
    "USER_STUDENT_IS_COMPLETING"
  );

  static readonly USER_STUDENT_SEND_RESET_PASSWORD_EMAIL_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "This user student account has already exceeded the daily limit of 3 reset password emails.",
      "La cuenta de usuario estudiante ya ha excedido el límite diario de 3 correos electrónicos de restablecimiento de contraseña.",
      "USER_STUDENT_SEND_RESET_PASSWORD_EMAIL_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_SEND_ACCOUNT_COMPLETION_EMAIL_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "This user student account has exceeded the daily limit of 3 account completion emails.",
      "La cuenta de usuario estudiante ha excedido el límite diario de 3 correos electrónicos de finalización de cuenta.",
      "USER_STUDENT_SEND_ACCOUNT_COMPLETION_EMAIL_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_UPDATE_PROFILE_PICTURE_DAILY_LIMIT =
    new KobleException(
      "The user student account has exceeded the daily limit of 3 profile picture uploads.",
      "La cuenta de usuario estudiante ha excedido el límite diario de 3 cargas de fotos de perfil.",
      "USER_STUDENT_UPDATE_PROFILE_PICTURE_DAILY_LIMIT"
    );

  static readonly USER_STUDENT_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT =
    new KobleException(
      "The user student account has exceeded the daily limit of 3 background picture uploads.",
      "La cuenta de usuario estudiante ha excedido el límite diario de 3 cargas de fotos de fondo.",
      "USER_STUDENT_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT"
    );

  static readonly USER_STUDENT_PHONE_TAKEN = new KobleException(
    "This phone is already taken by a user student account.",
    "Este teléfono ya ha sido tomado por otra cuenta de usuario estudiante.",
    "USER_STUDENT_PHONE_TAKEN"
  );

  static readonly USER_STUDENT_ACCOUNT_COMPLETED_PASSWORD_NOT_SET =
    new KobleException(
      "The user student account has been created and completed without setting a password.",
      "La cuenta de usuario estudiante ha sido creada y completada sin establecer una contraseña.",
      "USER_STUDENT_ACCOUNT_COMPLETED_PASSWORD_NOT_SET"
    );

  static readonly USER_STUDENT_EDUCATION_LIMIT_EXCEEDED = new KobleException(
    "The user student has exceeded the limit of 5 education entries.",
    "El usuario estudiante ha excedido el límite de 5 entradas de estudios.",
    "USER_STUDENT_EDUCATION_LIMIT_EXCEEDED"
  );

  static readonly USER_STUDENT_EXTRA_CURRICULAR_ACTIVITY_LIMIT_EXCEEDED =
    new KobleException(
      "The user student has exceeded the limit of 10 extracurricular activity entries.",
      "El usuario estudiante ha excedido el límite de 10 entradas de actividades extracurriculares.",
      "USER_STUDENT_EXTRA_CURRICULAR_ACTIVITY_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_UNAUTHORIZED_EXTRA_CURRICULAR_ACTIVITY_UPDATE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to update this extracurricular activity.",
      "El usuario estudiante no está autorizado para actualizar esta actividad extracurricular.",
      "USER_STUDENT_UNAUTHORIZED_EXTRA_CURRICULAR_ACTIVITY_UPDATE_ATTEMPT"
    );

  static readonly USER_STUDENT_UNAUTHORIZED_EXTRA_CURRICULAR_ACTIVITY_DELETE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to delete this extracurricular activity.",
      "El usuario estudiante no está autorizado para eliminar esta actividad extracurricular.",
      "USER_STUDENT_UNAUTHORIZED_EXTRA_CURRICULAR_ACTIVITY_DELETE_ATTEMPT"
    );

  static readonly USER_STUDENT_ACCOUNT_COMPLETION_TOKEN_NOT_FOUND =
    new KobleException(
      "The provided user student account completion token could not be found.",
      "El token de finalización de cuenta de usuario estudiante proporcionado no se pudo encontrar.",
      "USER_STUDENT_ACCOUNT_COMPLETION_TOKEN_NOT_FOUND"
    );

  static readonly USER_STUDENT_WORK_EXPERIENCE_LIMIT_EXCEEDED =
    new KobleException(
      "The user student has exceeded the limit of 10 work experience entries.",
      "El usuario estudiante ha excedido el límite de 10 entradas de experiencia laboral.",
      "USER_STUDENT_WORK_EXPERIENCE_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_UNAUTHORIZED_WORK_EXPERIENCE_UPDATE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to update this work experience.",
      "El usuario estudiante no está autorizado para actualizar esta experiencia laboral.",
      "USER_STUDENT_UNAUTHORIZED_WORK_EXPERIENCE_UPDATE_ATTEMPT"
    );

  static readonly USER_STUDENT_UNAUTHORIZED_EDUCATION_UPDATE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to update this education.",
      "El usuario estudiante no está autorizado para actualizar esta educación",
      "USER_STUDENT_UNAUTHORIZED_EDUCATION_UPDATE_ATTEMPT"
    );

  static readonly USER_STUDENT_UNAUTHORIZED_WORK_EXPERIENCE_DELETE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to delete this work experience.",
      "El usuario estudiante no está autorizado para eliminar esta experiencia laboral.",
      "USER_STUDENT_UNAUTHORIZED_WORK_EXPERIENCE_DELETE_ATTEMPT"
    );

  static readonly USER_STUDENT_INVALID_SOCIAL_MEDIA_URL_LIST =
    new KobleException(
      "The provided social media URL list is invalid. It must contain 7 elements at most.",
      "La lista de URL de redes sociales proporcionada no es válida. Debe contener 7 elementos como máximo.",
      "USER_STUDENT_INVALID_SOCIAL_MEDIA_URL_LIST"
    );

  static readonly USER_STUDENT_INVALID_INTERESTING_FACT = new KobleException(
    "The provided interesting fact is invalid. It must only contain alphanumeric characters, spaces, permitted punctuation marks (commas, semicolons, periods, hyphens, underscores, parentheses), it should be between 10 and 100 characters.",
    "El dato curioso proporcionado no es válido. Sólamente puede contener letras, números, espacios, signos de puntuación permitidos (comas, puntos y comas, puntos, guiones, guiones bajos, paréntesis), debe tener entre 10 y 100 caracteres.",
    "USER_STUDENT_INVALID_INTERESTING_FACT"
  );

  static readonly USER_STUDENT_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED =
    new KobleException(
      "The profile picture size limit of 20MB has been exceeded.",
      "Se ha excedido el límite de tamaño de la foto de perfil de 20MB.",
      "USER_STUDENT_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_INVALID_PROFILE_IMAGE = new KobleException(
    "The profile picture is invalid. It must be a JPG, JPEG, PNG or GIF image.",
    "La foto de perfil no es válida. Debe ser una imagen JPG, JPEG, PNG o GIF.",
    "USER_STUDENT_INVALID_PROFILE_IMAGE"
  );

  static readonly USER_STUDENT_UNAUTHORIZED_ACADEMIC_PROJECT_UPDATE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to update this academic project.",
      "El usuario estudiante no está autorizado para actualizar este proyecto académico.",
      "USER_STUDENT_UNAUTHORIZED_ACADEMIC_PROJECT_UPDATE_ATTEMPT"
    );

  static readonly USER_STUDENT_ACADEMIC_PROJECT_LIMIT_EXCEEDED =
    new KobleException(
      "The user student has exceeded the limit of 5 academic project entries.",
      "El usuario estudiante ha excedido el límite de 5 entradas de proyectos academico.",
      "USER_STUDENT_ACADEMIC_PROJECT_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_UNAUTHORIZED_CERTIFICATION_UPDATE_ATTEMPT =
    new KobleException(
      "The user student is not authorized to update this certification.",
      "El usuario estudiante no está autorizado para actualizar esta certificación.",
      "USER_STUDENT_UNAUTHORIZED_CERTIFICATION_UPDATE_ATTEMPT"
    );

  static readonly USER_STUDENT_CERTIFICATION_LIMIT_EXCEEDED =
    new KobleException(
      "The user student has exceeded the limit of 10 certification entries.",
      "El usuario estudiante ha excedido el límite de 10 entradas de certificaciones.",
      "USER_STUDENT_CERTIFICATION_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_UPDATE_PASSWORD_WRONG_CURRENT_PASSWORD =
    new KobleException(
      "The provided current password of the user student is incorrect.",
      "La contraseña actual proporcionada del usuario estudiante es incorrecta.",
      "USER_STUDENT_UPDATE_PASSWORD_WRONG_CURRENT_PASSWORD"
    );

  static readonly USER_STUDENT_UPDATE_PHONE_SEND_PIN_DAILY_LIMIT_EXCEEDED =
    new KobleException(
      "The user student account has exceeded his daily limit for sending phone pins, 10 per day.",
      "La cuenta de usuario estudiante ha excedido su límite diario para enviar pines de teléfono, 10 por día.",
      "USER_STUDENT_UPDATE_PHONE_SEND_PIN_DAILY_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_UPDATE_PHONE_INVALID_PIN = new KobleException(
    "The provided phone pin is invalid. It must be a 6-digit number.",
    "El pin de teléfono proporcionado no es válido. Debe ser un número de 6 dígitos.",
    "USER_STUDENT_UPDATE_PHONE_INVALID_PIN"
  );

  static readonly USER_STUDENT_UPDATE_PHONE_WRONG_CURRENT_PASSWORD =
    new KobleException(
      "The provided current password of the user student is incorrect.",
      "La contraseña actual proporcionada del usuario estudiante es incorrecta.",
      "USER_STUDENT_UPDATE_PHONE_WRONG_CURRENT_PASSWORD"
    );

  static readonly USER_STUDENT_UPDATE_PHONE_TWILIO_VERIFICATION_FAILED =
    new KobleException(
      "The twilio verification has failed.",
      "La verificación de twilio ha fallado.",
      "USER_STUDENT_UPDATE_PHONE_TWILIO_VERIFICATION_FAILED"
    );

  static readonly USER_STUDENT_UPDATE_PASSWORD_INVALID_NEW_PASSWORD =
    new KobleException(
      "The provided user student new password is invalid. It can only contain alphanumeric characters, special characters, and must be between 12 and 64 characters in length.",
      "La nueva contraseña proporcionada del usuario estudiante no es válida. Sólamente puede contener caracteres alfanuméricos, caracteres especiales, y debe tener entre 12 y 64 caracteres de longitud.",
      "USER_STUDENT_UPDATE_PASSWORD_INVALID_NEW_PASSWORD"
    );

  static readonly USER_STUDENT_INVALID_KEY_COMPETENCY_LIST = new KobleException(
    "The provided key competency list is invalid. It must contain between 1 and 20 elements.",
    "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 20 elementos.",
    "USER_STUDENT_INVALID_KEY_COMPETENCY_LIST"
  );

  static readonly USER_STUDENT_INVALID_LANGUAGE_LIST = new KobleException(
    "The provided language list is invalid. It must contain a maximum of 3 elements.",
    "La lista de idiomas proporcionada no es válida. Debe contener un máximo de 3 elementos.",
    "USER_STUDENT_INVALID_LANGUAGE_LIST"
  );

  static readonly USER_STUDENT_INVALID_SPECIAL_REQUIREMENT_LIST =
    new KobleException(
      "The provided special requirement list is invalid. It must contain a maximum of 6 elements.",
      "La lista de requerimientos especiales proporcionada no es válida. Debe contener un máximo de 6 elementos.",
      "USER_STUDENT_INVALID_SPECIAL_REQUIREMENT_LIST"
    );

  static readonly USER_STUDENT_INVALID_HOBBY_LIST = new KobleException(
    "The provided hobby list is invalid. It must contain a maximum of 3 elements.",
    "La lista de hobbies proporcionada no es válida. Debe contener un máximo de 3 elementos.",
    "USER_STUDENT_INVALID_HOBBY_LIST"
  );

  static readonly USER_STUDENT_INVALID_EXPECTATION_LIST = new KobleException(
    "The provided expectation list is invalid. It must contain between 1 and 10 elements.",
    "La lista de expectativas proporcionada no es válida. Debe contener entre 1 y 10 elementos.",
    "USER_STUDENT_INVALID_EXPECTATION_LIST"
  );

  static readonly USER_STUDENT_UNAUTHORIZED_COMPATIBILITY_SCORE_CREATION_ATTEMPT =
    new KobleException(
      "The user student is not authorized to create this compatibility score.",
      "El usuario estudiante no está autorizado para crear este score de compatibilidad.",
      "USER_STUDENT_UNAUTHORIZED_COMPATIBILITY_SCORE_CREATION_ATTEMPT"
    );

  static readonly USER_STUDENT_INVALID_FILTER_AGE_INTERVAL = new KobleException(
    "The provided age interval is invalid. The minimum age must be greater than or equal to 0.",
    "El intervalo de edad proporcionado no es válido. La edad mínima debe ser mayor o igual a 0.",
    "USER_STUDENT_INVALID_FILTER_AGE_INTERVAL"
  );

  static readonly USER_STUDENT_CAREER_COMPLETION_PERCENTAGE_INVALID =
    new KobleException(
      "The provided career completion percentage is invalid. It must be between 0 and 100.",
      "El porcentaje de finalización de carrera proporcionado no es válido. Debe estar entre 0 y 100.",
      "USER_STUDENT_CAREER_COMPLETION_PERCENTAGE_INVALID"
    );

  static readonly USER_STUDENT_ONBOARDING_INVALID_EXPECTATION_LIST =
    new KobleException(
      "The provided expectation list is invalid. It must contain between 1 and 10 elements.",
      "La lista de expectativas proporcionada no es válida. Debe contener entre 1 y 10 elementos.",
      "USER_STUDENT_ONBOARDING_INVALID_EXPECTATION_LIST"
    );

  static readonly USER_STUDENT_ONBOARDING_INVALID_KEY_COMPETENCY_LIST =
    new KobleException(
      "The provided key competency list is invalid. It must contain between 1 and 20 elements.",
      "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 20 elementos.",
      "USER_STUDENT_ONBOARDING_INVALID_KEY_COMPETENCY_LIST"
    );

  static readonly USER_STUDENT_INVALID_PAGINATION_LIMIT = new KobleException(
    "The provided limit must be less than or equal to 36.",
    "El límite proporcionado debe ser menor o igual a 36.",
    "USER_STUDENT_INVALID_PAGINATION_LIMIT"
  );

  static readonly USER_STUDENT_THIRD_PARTY_JOB_NO_CLICKS_LEFT =
    new KobleException(
      "The provided user student has no third party job clicks left.",
      "El usuario estudiante no tiene más clics de trabajo de terceros.",
      "USER_STUDENT_THIRD_PARTY_JOB_NO_CLICKS_LEFT"
    );

  static readonly USER_STUDENT_ONBOARDING_INVALID_SKILL_LIST =
    new KobleException(
      "The provided skill list is invalid. It must contain between 1 and 20 elements.",
      "La lista de habilidades proporcionada no es válida. Debe contener entre 1 y 20 elementos.",
      "USER_STUDENT_ONBOARDING_INVALID_SKILL_LIST"
    );

  static readonly USER_STUDENT_LANGUAGE_ID_NOT_FOUND = new KobleException(
    "The provided user student language ID could not be found.",
    "El identificador del idioma del estudiante no se pudo encontrar.",
    "USER_STUDENT_LANGUAGE_ID_NOT_FOUND"
  );

  static readonly USER_STUDENT_LANGUAGE_LANGUAGE_ID_LIMIT_EXCEEDED =
    new KobleException(
      "The user student has exceeded the maximum number of user student languages by language ID, since there may only be 1 entry for a specific language.",
      "El estudiante ha excedido el número máximo de idiomas de estudiante por identificador de idioma, ya que solo puede haber 1 entrada para un idioma específico.",
      "USER_STUDENT_LANGUAGE_LANGUAGE_ID_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_LIKE_NOT_FOUND = new KobleException(
    "The user student like was not found.",
    "El like del usuario estudiante no fue encontrado.",
    "USER_STUDENT_LIKE_NOT_FOUND"
  );

  static readonly USER_STUDENT_LIKE_ALREADY_EXISTS = new KobleException(
    "The user student like already exists.",
    "El like del usuario estudiante ya existe.",
    "USER_STUDENT_LIKE_ALREADY_EXISTS"
  );

  static readonly USER_STUDENT_LIKE_USER_STUDENT_NOT_COMPLETED =
    new KobleException(
      "The user student must be completed to create a like.",
      "El usuario estudiante debe estar completado para crear un like.",
      "USER_STUDENT_LIKE_USER_STUDENT_NOT_COMPLETED"
    );

  static readonly USER_STUDENT_PREFERENCES_INDUSTRY_IDS_LIMIT_EXCEEDED =
    new KobleException(
      "The provided industry IDs list exceeds the maximum allowed of 5 elements.",
      "La lista de ids. de industrias proporcionada excede el máximo permitido de 5 elementos.",
      "USER_STUDENT_PREFERENCES_INDUSTRY_IDS_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_PREFERENCES_WORK_MODE_IDS_LIMIT_EXCEEDED =
    new KobleException(
      "The provided work mode IDs list exceeds the maximum allowed of 2 elements.",
      "La lista de ids. de modos de trabajo proporcionada excede el máximo permitido de 2 elementos.",
      "USER_STUDENT_PREFERENCES_WORK_MODE_IDS_LIMIT_EXCEEDED"
    );

  static readonly USER_STUDENT_SOCIAL_MEDIA_INVALID_INSTAGRAM_USER_NAME =
    new KobleException(
      "The provided Instagram username is invalid. Only alphanumeric characters, in addition to the symbols '-', '.', '_' are allowed, with a maxmum of 30 characters in length.",
      "El nombre de usuario de Instagram proporcionado no es válido. Solo se permiten caracteres alfanuméricos, además de los símbolos '-', '.', '_' con una longitud máxima de 30 caracteres.",
      "USER_STUDENT_SOCIAL_MEDIA_INVALID_INSTAGRAM_USER_NAME"
    );

  static readonly USER_STUDENT_SOCIAL_MEDIA_INVALID_FACEBOOK_USER_NAME =
    new KobleException(
      "The provided Facebook username is invalid. Only alphanumeric characters, in addition to the symbols '-', '.', '_' are allowed, with a maximum of 50 characters in length.",
      "El nombre de usuario de Facebook proporcionado no es válido. Solo se permiten caracteres alfanuméricos, además de los símbolos '-', '.', '_' con una longitud máxima de 50 caracteres.",
      "USER_STUDENT_SOCIAL_MEDIA_INVALID_FACEBOOK_USER_NAME"
    );

  static readonly USER_STUDENT_SOCIAL_MEDIA_INVALID_LINKEDIN_USER_NAME =
    new KobleException(
      "The provided Linkedin username is invalid. Only alphanumeric characters, in addition to the symbols '-', '.', '_' are allowed, with a maximum of 60 characters in length.",
      "El nombre de usuario de Linkedin proporcionado no es válido. Solo se permiten caracteres alfanuméricos, además de los símbolos '-', '.', '_' con una longitud máxima de 60 caracteres.",
      "USER_STUDENT_SOCIAL_MEDIA_INVALID_LINKEDIN_USER_NAME"
    );

  static readonly USER_STUDENT_TOUR_INVALID_USER_STUDENT_TOUR =
    new KobleException(
      "The provided user student tour is invalid.",
      "El tour de usuario estudiante proporcionado no es válido.",
      "USER_STUDENT_TOUR_INVALID_USER_STUDENT_TOUR"
    );

  static readonly WORK_CONTRACT_TYPE_ID_NOT_FOUND = new KobleException(
    "The provided work contract type ID could not be found.",
    "El identificador del tipo de contrato de trabajo proporcionado no se pudo encontrar.",
    "WORK_CONTRACT_TYPE_ID_NOT_FOUND"
  );

  static readonly WORK_ENVIRONMENT_ID_NOT_FOUND = new KobleException(
    "The provided work environment identifier was not found.",
    "El identificador del ambiente laboral proporcionado no se pudo encontrar.",
    "WORK_ENVIRONMENT_ID_NOT_FOUND"
  );

  static readonly WORK_EXPERIENCE_ID_NOT_FOUND = new KobleException(
    "The provided work experience ID could not be found.",
    "El identificador de la experiencia laboral proporcionada no pudo ser encontrado.",
    "WORK_EXPERIENCE_ID_NOT_FOUND"
  );

  static readonly WORK_EXPERIENCE_INVALID_BUSINESS_NAME = new KobleException(
    "The provided work experience business name is invalid. It must only contain letters, spaces, permitted punctuation marks (commas, semicolons, periods, hyphens, underscores, parentheses), should be between 3 and 128 characters in length and cannot consist solely of whitespace.",
    "El nombre de negocio de la experiencia laboral proporcionada no es válido. Debe contener solo letras, espacios, signos de puntuación permitidos (comas, puntos y comas, puntos, guiones, guiones bajos, paréntesis), tener entre 3 y 128 caracteres y no puede consistir únicamente de espacios en blanco.",
    "WORK_EXPERIENCE_INVALID_BUSINESS_NAME"
  );

  static readonly WORK_EXPERIENCE_INVALID_START_DATE = new KobleException(
    "The provided work experience start date is invalid. It must be a valid epoch date. It must be prior to the end date and must be in the past.",
    "La fecha de inicio de la experiencia laboral proporcionada no es válida. Debe ser una fecha de época válida. Debe ser anterior a la fecha de finalización y debe estar en el pasado.",
    "WORK_EXPERIENCE_INVALID_START_DATE"
  );

  static readonly WORK_EXPERIENCE_INVALID_END_DATE = new KobleException(
    "The provided work experience end date is invalid. It must be greater than the start date.",
    "La fecha de finalización de la experiencia laboral proporcionada no es válida. Debe ser una fecha de época válida. Debe ser posterior a la fecha de inicio.",
    "WORK_EXPERIENCE_INVALID_END_DATE"
  );

  static readonly WORK_EXPERIENCE_INVALID_ABOUT = new KobleException(
    "The provided work experience about is invalid. It can only contain alphabetic characters (with or without diacritics), numbers, punctuation marks, spaces or newlines, it should be between 1 and 500 characters, and no more than 20 lines",
    "El apartado 'acerca de' de la experiencia laboral proporcionada no es válido. Sólamente puede contener caracteres alfabéticos (con o sin diacríticos), números, signos de puntuación, espacios o saltos de línea, debe tener entre 1 y 500 caracteres, y no más de 20 líneas",
    "WORK_EXPERIENCE_INVALID_ABOUT"
  );

  static readonly WORK_EXPERIENCE_INVALID_JOB_TITLE = new KobleException(
    "The provided work experience job title is invalid. It must only contain alphanumeric characters, spaces, numbers, hyphens, and should be between 1 and 100 characters",
    "El título del trabajo de la experiencia laboral proporcionada no es válido. Sólamente puede contener caracteres alfanuméricos, espacios, números, guiones y debe tener entre 1 y 100 caracteres.",
    "WORK_EXPERIENCE_INVALID_JOB_TITLE"
  );

  static readonly WORK_EXPERIENCE_INVALID_KEY_COMPETENCY_LIST =
    new KobleException(
      "The provided key competency list is invalid. It must contain between 1 and 5 elements.",
      "La lista de competencias clave proporcionada no es válida. Debe contener entre 1 y 5 elementos.",
      "WORK_EXPERIENCE_INVALID_KEY_COMPETENCY_LIST"
    );

  static readonly WORK_MODE_ID_NOT_FOUND = new KobleException(
    "The provided work mode ID could not be found.",
    "El identificador del modo de trabajo proporcionado no pudo ser encontrado.",
    "WORK_MODE_ID_NOT_FOUND"
  );

  static readonly WORK_POSITION_ID_NOT_FOUND = new KobleException(
    "The provided work position ID could not be found.",
    "El identificador de la posición laboral proporcionada no pudo ser encontrado.",
    "WORK_POSITION_ID_NOT_FOUND"
  );

  static readonly WORK_SCHEDULE_TYPE_ID_NOT_FOUND = new KobleException(
    "The provided work schedule type ID could not be found.",
    "El identificador del tipo de horario laboral proporcionado no pudo ser encontrado.",
    "WORK_SCHEDULE_TYPE_ID_NOT_FOUND"
  );

  static readonly TWILIO_INVALID_COUNTRY_CODE = new KobleException(
    "Invalid country code. It must be between 1 and 99999.",
    "Código de país no válido. Debe estar entre 1 y 99999.",
    "TWILIO_INVALID_COUNTRY_CODE"
  );

  static readonly TWILIO_INVALID_PHONE_NUMBER = new KobleException(
    "The provided phone number is invalid. It must be a sequence of 10 digits only.",
    "El número de teléfono proporcionado no es válido. Debe ser una secuencia de 10 dígitos solamente.",
    "TWILIO_INVALID_PHONE_NUMBER"
  );

  static readonly TWILIO_INVALID_CHANNEL = new KobleException(
    "The provided channel is invalid. Valid channels are 'sms' (standard SMS messages), 'whatsapp' (WhatsApp messages), 'sna' (Silent Network Authentication), and 'call' (phone call verification).",
    "El canal proporcionado no es válido. Los canales válidos son 'sms' (mensajes SMS estándar), 'whatsapp' (mensajes de WhatsApp), 'sna' (Autenticación de red silenciosa) y 'call' (verificación por llamada telefónica).",
    "TWILIO_INVALID_CHANNEL"
  );

  static readonly TWILIO_INVALID_CODE = new KobleException(
    "The provided code is invalid. The code must be 4 to 10 characters in length and consist of digits only.",
    "El código proporcionado no es válido. El código debe tener de 4 a 10 caracteres y consistir de dígitos sólamente.",
    "TWILIO_INVALID_CODE"
  );

  static readonly TWILIO_INVALID_PHONE_VERIFICATION_CODE = new KobleException(
    "The provided phone verification code is invalid.",
    "El código de verificación de teléfono proporcionado no es válido.",
    "TWILIO_INVALID_PHONE_VERIFICATION_CODE"
  );

  static readonly GOOGLE_INVALID_PLACE_ID = new KobleException(
    "The provided place ID could not be found.",
    "El identificador de lugar proporcionado no se pudo encontrar.",
    "GOOGLE_INVALID_PLACE_ID"
  );

  static readonly GOOGLE_INVALID_CAPTCHA_TOKEN = new KobleException(
    "The provided captcha token is not valid.",
    "El captcha proporcionado no es válido.",
    "GOOGLE_INVALID_CAPTCHA_TOKEN"
  );

  static readonly GOOGLE_INVALID_JWT_TOKEN = new KobleException(
    "The provided google jwt token is invalid.",
    "El token de google jwt proporcionado no es válido.",
    "GOOGLE_INVALID_JWT_TOKEN"
  );

  static readonly AZURE_BLOB_FILE_NOT_FOUND = new KobleException(
    "The provided file could not be found.",
    "El archivo proporcionado no se pudo encontrar.",
    "AZURE_BLOB_FILE_NOT_FOUND"
  );

  static readonly all = [
    KobleErrors.ACADEMIC_PROJECT_ID_NOT_FOUND,
    KobleErrors.ACADEMIC_PROJECT_INVALID_START_DATE,
    KobleErrors.ACADEMIC_PROJECT_INVALID_END_DATE,
    KobleErrors.ACADEMIC_PROJECT_INVALID_ABOUT,
    KobleErrors.ACADEMIC_PROJECT_INVALID_TITLE,
    KobleErrors.ACADEMIC_PROJECT_INVALID_KEY_COMPETENCY_LIST,
    KobleErrors.AREA_ID_NOT_FOUND,
    KobleErrors.BENEFIT_ID_NOT_FOUND,
    KobleErrors.BOUNDING_BOX_INVALID_LATITUDE_RELATIONSHIP,
    KobleErrors.BOUNDING_BOX_INVALID_LONGITUDE_RELATIONSHIP,
    KobleErrors.BUSINESS_ID_NOT_FOUND,
    KobleErrors.BUSINESS_UPDATE_PROFILE_PICTURE_DAILY_LIMIT_EXCEEDED,
    KobleErrors.BUSINESS_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT_EXCEEDED,
    KobleErrors.BUSINESS_INVALID_NAME,
    KobleErrors.BUSINESS_STRIPE_SUBSCRIPTION_URL_CREATION_DAILY_LIMIT_EXCEEDED,
    KobleErrors.BUSINESS_INVALID_FOUNDATION_DATE,
    KobleErrors.BUSINESS_INVALID_SOCIAL_MEDIA_URL_LIST,
    KobleErrors.BUSINESS_INVALID_WEBSITE_URL,
    KobleErrors.BUSINESS_STRIPE_BILLING_PORTAL_URL_CREATION_DAILY_LIMIT_EXCEEDED,
    KobleErrors.BUSINESS_DOESNT_HAVE_STRIPE_CUSTOMER_ID,
    KobleErrors.BUSINESS_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED,
    KobleErrors.BUSINESS_BACKGROUND_IMAGE_SIZE_LIMIT_EXCEEDED,
    KobleErrors.BUSINESS_INVALID_PROFILE_IMAGE,
    KobleErrors.BUSINESS_INVALID_BACKGROUND_IMAGE,
    KobleErrors.BUSINESS_INVALID_ABOUT,
    KobleErrors.BUSINESS_INVALID_TYPICAL_DAY_DESCRIPTION,
    KobleErrors.BUSINESS_STRIPE_CUSTOMER_ID_NOT_FOUND,
    KobleErrors.BUSINESS_NO_JOB_SLOTS_AVAILABLE,
    KobleErrors.BUSINESS_INVALID_WHY_JOIN_DESCRIPTION,
    KobleErrors.BUSINESS_NO_LIKES_AVAILABLE,
    KobleErrors.BUSINESS_NO_SUPER_LIKES_AVAILABLE,
    KobleErrors.BUSINESS_USER_RECRUITER_NOT_COMPLETED,
    KobleErrors.BUSINESS_PROFILE_INCOMPLETE,
    KobleErrors.BUSINESS_PRICING_ID_NOT_FOUND,
    KobleErrors.BUSINESS_PRODUCT_RESTRICTED_COMBINATION,
    KobleErrors.BUSINESS_SIZE_ID_NOT_FOUND,
    KobleErrors.CAREER_ID_NOT_FOUND,
    KobleErrors.CERTIFICATION_ID_NOT_FOUND,
    KobleErrors.CERTIFICATION_INVALID_START_DATE,
    KobleErrors.CERTIFICATION_INVALID_END_DATE,
    KobleErrors.CERTIFICATION_INVALID_INSTITUTION_NAME,
    KobleErrors.CERTIFICATION_INVALID_TITLE,
    KobleErrors.CERTIFICATION_INVALID_ABOUT,
    KobleErrors.CERTIFICATION_INVALID_KEY_COMPETENCIES_LIST,
    KobleErrors.CHAT_ID_NOT_FOUND,
    KobleErrors.CHAT_IS_DISABLED,
    KobleErrors.CHAT_UNAUTHORIZED_UPDATE,
    KobleErrors.CHAT_MESSAGE_ID_NOT_FOUND,
    KobleErrors.CHAT_MESSAGE_INVALID_MESSAGE_FORMAT,
    KobleErrors.FORBIDDEN,
    KobleErrors.NOT_IMPLEMENTED,
    KobleErrors.COORDINATE_LATITUDE_OUT_OF_RANGE,
    KobleErrors.COORDINATE_LONGITUDE_OUT_OF_RANGE,
    KobleErrors.CURRENCY_ID_NOT_FOUND,
    KobleErrors.EDUCATION_ID_NOT_FOUND,
    KobleErrors.EDUCATION_INVALID_START_DATE,
    KobleErrors.EDUCATION_INVALID_END_DATE,
    KobleErrors.EDUCATION_INVALID_ABOUT,
    KobleErrors.EDUCATION_INVALID_KEY_COMPETENCIES_LIST,
    KobleErrors.EXPECTATION_ID_NOT_FOUND,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_ID_NOT_FOUND,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_INVALID_START_DATE,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_INVALID_END_DATE,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_INVALID_TITLE,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_INVALID_SUB_TITLE,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_INVALID_ABOUT,
    KobleErrors.EXTRA_CURRICULAR_ACTIVITY_INVALID_KEY_COMPETENCY_LIST,
    KobleErrors.GENDER_ID_NOT_FOUND,
    KobleErrors.HOBBY_ID_NOT_FOUND,
    KobleErrors.IDENTITY_EMAIL_NOT_FOUND,
    KobleErrors.IDENTITY_CLIENT_NOT_FOUND,
    KobleErrors.IDENTITY_REFRESH_TOKEN_NOT_FOUND,
    KobleErrors.IDENTITY_EMAIL_AND_PASSWORD_DONT_MATCH,
    KobleErrors.IDENTITY_ACCESS_TOKEN_NOT_FOUND,
    KobleErrors.IDENTITY_RESET_PASSWORD_TOKEN_NOT_FOUND,
    KobleErrors.IDENTITY_INVALID_PHONE_VERIFICATION_CODE,
    KobleErrors.IDENTITY_USER_TYPE_NOT_FOUND,
    KobleErrors.IDENTITY_INVALID_REFERRAL_CODE_FORMAT,
    KobleErrors.IDENTITY_BACK_OFFICE_IP_NOT_WHITELISTED,
    KobleErrors.INDUSTRY_ID_NOT_FOUND,
    KobleErrors.INT_INTERVAL_MIN_MUST_BE_LESS_THAN_MAX,
    KobleErrors.JOB_CATEGORY_ID_NOT_FOUND,
    KobleErrors.JOB_ID_NOT_FOUND,
    KobleErrors.JOB_INVALID_JOB_TITLE,
    KobleErrors.JOB_INVALID_ABOUT,
    KobleErrors.JOB_INVALID_INFO,
    KobleErrors.JOB_INVALID_SALARY_RANGE,
    KobleErrors.JOB_INVALID_KEY_COMPETENCY_LIST,
    KobleErrors.JOB_INVALID_WORK_MODE_LIST,
    KobleErrors.JOB_INVALID_WORK_CONTRACT_TYPE_LIST,
    KobleErrors.JOB_INVALID_WORK_SCHEDULE_TYPE_LIST,
    KobleErrors.JOB_INVALID_BENEFIT_LIST,
    KobleErrors.JOB_INVALID_CAREER_LIST,
    KobleErrors.JOB_INVALID_LANGUAGE_LIST,
    KobleErrors.JOB_INVALID_SPECIAL_REQUIREMENT_LIST,
    KobleErrors.JOB_LANGUAGE_ID_ALREADY_EXIST,
    KobleErrors.JOB_IS_NOT_SLOTTED,
    KobleErrors.JOB_BACKGROUND_IMAGE_SIZE_LIMIT_EXCEEDED,
    KobleErrors.JOB_INVALID_BACKGROUND_IMAGE,
    KobleErrors.JOB_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT_EXCEEDED,
    KobleErrors.JOB_INVALID_PLACE_ID,
    KobleErrors.JOB_IS_SLOTTED_CANNOT_BE_UPDATED,
    KobleErrors.JOB_IS_NOT_COMPLETE,
    KobleErrors.JOB_INVALID_SKILL_LIST,
    KobleErrors.JOB_SLOT_REMOVAL_REASON_ID_NOT_FOUND,
    KobleErrors.KEY_COMPETENCY_ID_NOT_FOUND,
    KobleErrors.LANGUAGE_ID_NOT_FOUND,
    KobleErrors.LANGUAGE_LEVEL_ID_NOT_FOUND,
    KobleErrors.MAILING_SUBSCRIBER_EMAIL_TAKEN,
    KobleErrors.MATCH_ID_NOT_FOUND,
    KobleErrors.MATCH_ALREADY_ACTIVE,
    KobleErrors.COMPATIBILITY_SCORE_ID_NOT_FOUND,
    KobleErrors.COMPATIBILITY_SCORE_INVALID_SCORE,
    KobleErrors.COMPATIBILITY_SCORE_ALREADY_PROCESSED,
    KobleErrors.COMPATIBILITY_SCORE_BUSINESS_NOT_PREMIUM,
    KobleErrors.COMPATIBILITY_SCORE_ALREADY_EXISTS,
    KobleErrors.MATCH_STATUS_CHANGE_REASON_ID_NOT_FOUND,
    KobleErrors.MATCH_STATUS_CHANGE_REASON_INVALID_MATCH_STATUS_ID,
    KobleErrors.MATCH_STATUS_CHANGE_REASON_ID_REQUIRED,
    KobleErrors.MATCH_STATUS_CHANGE_REASON_INVALID_DESCRIPTION,
    KobleErrors.MATCH_STATUS_CHANGE_REASON_DESCRIPTION_REQUIRED,
    KobleErrors.MATCH_STATUS_CHANGE_REASON_DESCRIPTION_NOT_REQUIRED,
    KobleErrors.MATCH_STATUS_ID_NOT_FOUND,
    KobleErrors.UNAUTHORIZED_MATCH_STATUS_UPDATE,
    KobleErrors.NEW_CAREER_REQUEST_NAME_INVALID,
    KobleErrors.NEW_EMAIL_VERIFICATION_PIN_NOT_FOUND,
    KobleErrors.NEW_EMAIL_VERIFICATION_PIN_DAILY_LIMIT,
    KobleErrors.NEW_EMAIL_VERIFICATION_PIN_INVALID_EMAIL,
    KobleErrors.NEW_EMAIL_VERIFICATION_PIN_WRONG_CURRENT_PASSWORD,
    KobleErrors.NEW_EMAIL_VERIFICATION_PIN_EXPIRED,
    KobleErrors.NEW_EMAIL_VERIFICATION_PIN_INVALID,
    KobleErrors.NEW_UNIVERSITY_REQUEST_NAME_INVALID,
    KobleErrors.USER_EMAIL_NOTIFICATION_PREFERENCE_INVALID_USER_EMAIL_NOTIFICATION_PREFERENCE,
    KobleErrors.USER_EMAIL_NOTIFICATION_PREFERENCE_TOKEN_NOT_FOUND,
    KobleErrors.NOTIFICATION_ID_NOT_FOUND,
    KobleErrors.NOTIFICATION_UNAUTHORIZED_UPDATE_ATTEMPT,
    KobleErrors.COMMON_INVALID_LIMIT,
    KobleErrors.COMMON_INVALID_OFFSET,
    KobleErrors.PHONE_COUNTRY_CODE_NOT_FOUND,
    KobleErrors.PHONE_ALREADY_EXISTS,
    KobleErrors.PHONE_INVALID_PHONE_NUMBER,
    KobleErrors.PRONOUN_ID_NOT_FOUND,
    KobleErrors.SALARY_RANGE_ID_NOT_FOUND,
    KobleErrors.SKILL_ID_NOT_FOUND,
    KobleErrors.SOCIAL_MEDIA_ID_NOT_FOUND,
    KobleErrors.SOCIAL_MEDIA_URL_INVALID_URL,
    KobleErrors.SORT_BY_INVALID_SORT_ORDER,
    KobleErrors.SPECIAL_REQUIREMENT_ID_NOT_FOUND,
    KobleErrors.TEXT_GENERATOR_SERVICE_REFINE_TEXT_INVALID_TEXT,
    KobleErrors.TEXT_GENERATOR_SERVICE_RESUME_THIRD_PARTY_JOB_ABOUT_INVALID_TEXT,
    KobleErrors.TEXT_GENERATOR_SERVICE_INVALID_BUSINESS_NAME,
    KobleErrors.TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_BUSINESS_VISION_REQUIRED,
    KobleErrors.TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_BUSINESS_MISSION_REQUIRED,
    KobleErrors.TEXT_GENERATOR_SERVICE_GENERATE_BUSINESS_ABOUT_SLOGAN_REQUIRED,
    KobleErrors.TEXT_GENERATOR_SERVICE_INVALID_JOB_TITLE,
    KobleErrors.TEXT_GENERATOR_SERVICE_JUNGIAN_ARCHETYPE_ID_NOT_FOUND,
    KobleErrors.TEXT_GENERATOR_SERVICE_INVALID_JUNGIAN_ARCHETYPE_LIST,
    KobleErrors.TEXT_GENERATOR_SERVICE_INVALID_RESPONSIBILITY_LIST,
    KobleErrors.THIRD_PARTY_JOB_PLATFORM_ID_NOT_FOUND,
    KobleErrors.THIRD_PARTY_JOB_ID_NOT_FOUND,
    KobleErrors.THIRD_PARTY_JOB_PLATFORM_GIVEN_ID_ALREADY_EXISTS,
    KobleErrors.THIRD_PARTY_JOB_INVALID_URL,
    KobleErrors.THIRD_PARTY_JOB_INVALID_PUBLISH_AT,
    KobleErrors.THIRD_PARTY_JOB_INVALID_ABOUT,
    KobleErrors.THIRD_PARTY_JOB_INVALID_PAGINATION_LIMIT,
    KobleErrors.UNIVERSITY_ID_NOT_FOUND,
    KobleErrors.USER_RECRUITER_CHAT_ID_NOT_FOUND,
    KobleErrors.USER_RECRUITER_EMAIL_TAKEN,
    KobleErrors.USER_RECRUITER_ID_NOT_FOUND,
    KobleErrors.USER_RECRUITER_PHONE_TAKEN,
    KobleErrors.USER_RECRUITER_IS_COMPLETING,
    KobleErrors.USER_RECRUITER_EMAIL_DOESNT_EXIST,
    KobleErrors.USER_RECRUITER_ACCOUNT_ALREADY_COMPLETED,
    KobleErrors.USER_RECRUITER_SEND_ACCOUNT_COMPLETION_EMAIL_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_RECRUITER_SEND_RESET_PASSWORD_EMAIL_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_RECRUITER_ACCOUNT_COMPLETED_PASSWORD_NOT_SET,
    KobleErrors.USER_RECRUITER_ACCOUNT_COMPLETION_TOKEN_NOT_FOUND,
    KobleErrors.USER_RECRUITER_UNAUTHORIZED_BUSINESS_UPDATE_ATTEMPT,
    KobleErrors.USER_RECRUITER_INVALID_FIRST_NAME,
    KobleErrors.USER_RECRUITER_INVALID_LAST_NAME,
    KobleErrors.USER_RECRUITER_INVALID_DATE_OF_BIRTH,
    KobleErrors.USER_RECRUITER_INVALID_ABOUT,
    KobleErrors.USER_RECRUITER_INVALID_PASSWORD,
    KobleErrors.USER_RECRUITER_FREE_TRIAL_ALREADY_USED,
    KobleErrors.USER_RECRUITER_UNAUTHORIZED_JOB_UPDATE_ATTEMPT,
    KobleErrors.USER_RECRUITER_UNAUTHORIZED_USER_RECRUITER_CHAT_GET_ATTEMPT,
    KobleErrors.USER_RECRUITER_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED,
    KobleErrors.USER_RECRUITER_INVALID_PROFILE_IMAGE,
    KobleErrors.USER_RECRUITER_UPDATE_PROFILE_PICTURE_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_RECRUITER_MAX_BUSINESSES_ALLOWED_EXCEEDED,
    KobleErrors.USER_RECRUITER_UNAUTHORIZED_BUSINESS,
    KobleErrors.USER_RECRUITER_UPDATE_PASSWORD_WRONG_CURRENT_PASSWORD,
    KobleErrors.USER_RECRUITER_UPDATE_PHONE_SEND_PIN_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_RECRUITER_UPDATE_PHONE_INVALID_PIN,
    KobleErrors.USER_RECRUITER_UPDATE_PHONE_WRONG_CURRENT_PASSWORD,
    KobleErrors.USER_RECRUITER_UPDATE_PHONE_TWILIO_VERIFICATION_FAILED,
    KobleErrors.USER_RECRUITER_UPDATE_PASSWORD_INVALID_NEW_PASSWORD,
    KobleErrors.USER_RECRUITER_UNAUTHORIZED_MATCH_UPDATE_ATTEMPT,
    KobleErrors.USER_RECRUITER_UNAUTHORIZED_COMPATIBILITY_SCORE_CREATE_ATTEMPT,
    KobleErrors.USER_RECRUITER_LIKE_NOT_FOUND,
    KobleErrors.USER_RECRUITER_LIKE_ALREADY_EXISTS,
    KobleErrors.USER_RECRUITER_SUPER_LIKE_ALREADY_EXISTS,
    KobleErrors.USER_RECRUITER_LIKE_INVALID_MESSAGE,
    KobleErrors.USER_STUDENT_CHAT_ID_NOT_FOUND,
    KobleErrors.USER_STUDENT_EMAIL_TAKEN,
    KobleErrors.USER_STUDENT_ID_NOT_FOUND,
    KobleErrors.USER_STUDENT_INVALID_FIRST_NAME,
    KobleErrors.USER_STUDENT_INVALID_LAST_NAME,
    KobleErrors.USER_STUDENT_INVALID_DATE_OF_BIRTH,
    KobleErrors.USER_STUDENT_INVALID_ABOUT,
    KobleErrors.USER_STUDENT_INVALID_PASSWORD,
    KobleErrors.USER_STUDENT_ACCOUNT_ALREADY_COMPLETED,
    KobleErrors.USER_STUDENT_EMAIL_DOESNT_EXIST,
    KobleErrors.USER_STUDENT_IS_COMPLETING,
    KobleErrors.USER_STUDENT_SEND_RESET_PASSWORD_EMAIL_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_SEND_ACCOUNT_COMPLETION_EMAIL_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_UPDATE_PROFILE_PICTURE_DAILY_LIMIT,
    KobleErrors.USER_STUDENT_UPDATE_BACKGROUND_PICTURE_DAILY_LIMIT,
    KobleErrors.USER_STUDENT_PHONE_TAKEN,
    KobleErrors.USER_STUDENT_ACCOUNT_COMPLETED_PASSWORD_NOT_SET,
    KobleErrors.USER_STUDENT_EDUCATION_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_EXTRA_CURRICULAR_ACTIVITY_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_EXTRA_CURRICULAR_ACTIVITY_UPDATE_ATTEMPT,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_EXTRA_CURRICULAR_ACTIVITY_DELETE_ATTEMPT,
    KobleErrors.USER_STUDENT_ACCOUNT_COMPLETION_TOKEN_NOT_FOUND,
    KobleErrors.USER_STUDENT_WORK_EXPERIENCE_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_WORK_EXPERIENCE_UPDATE_ATTEMPT,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_EDUCATION_UPDATE_ATTEMPT,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_WORK_EXPERIENCE_DELETE_ATTEMPT,
    KobleErrors.USER_STUDENT_INVALID_SOCIAL_MEDIA_URL_LIST,
    KobleErrors.USER_STUDENT_INVALID_INTERESTING_FACT,
    KobleErrors.USER_STUDENT_PROFILE_IMAGE_SIZE_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_INVALID_PROFILE_IMAGE,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_ACADEMIC_PROJECT_UPDATE_ATTEMPT,
    KobleErrors.USER_STUDENT_ACADEMIC_PROJECT_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_CERTIFICATION_UPDATE_ATTEMPT,
    KobleErrors.USER_STUDENT_CERTIFICATION_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_UPDATE_PASSWORD_WRONG_CURRENT_PASSWORD,
    KobleErrors.USER_STUDENT_UPDATE_PHONE_SEND_PIN_DAILY_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_UPDATE_PHONE_INVALID_PIN,
    KobleErrors.USER_STUDENT_UPDATE_PHONE_WRONG_CURRENT_PASSWORD,
    KobleErrors.USER_STUDENT_UPDATE_PHONE_TWILIO_VERIFICATION_FAILED,
    KobleErrors.USER_STUDENT_UPDATE_PASSWORD_INVALID_NEW_PASSWORD,
    KobleErrors.USER_STUDENT_INVALID_KEY_COMPETENCY_LIST,
    KobleErrors.USER_STUDENT_INVALID_LANGUAGE_LIST,
    KobleErrors.USER_STUDENT_INVALID_SPECIAL_REQUIREMENT_LIST,
    KobleErrors.USER_STUDENT_INVALID_HOBBY_LIST,
    KobleErrors.USER_STUDENT_INVALID_EXPECTATION_LIST,
    KobleErrors.USER_STUDENT_UNAUTHORIZED_COMPATIBILITY_SCORE_CREATION_ATTEMPT,
    KobleErrors.USER_STUDENT_INVALID_FILTER_AGE_INTERVAL,
    KobleErrors.USER_STUDENT_CAREER_COMPLETION_PERCENTAGE_INVALID,
    KobleErrors.USER_STUDENT_ONBOARDING_INVALID_EXPECTATION_LIST,
    KobleErrors.USER_STUDENT_ONBOARDING_INVALID_KEY_COMPETENCY_LIST,
    KobleErrors.USER_STUDENT_INVALID_PAGINATION_LIMIT,
    KobleErrors.USER_STUDENT_THIRD_PARTY_JOB_NO_CLICKS_LEFT,
    KobleErrors.USER_STUDENT_ONBOARDING_INVALID_SKILL_LIST,
    KobleErrors.USER_STUDENT_LANGUAGE_ID_NOT_FOUND,
    KobleErrors.USER_STUDENT_LANGUAGE_LANGUAGE_ID_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_LIKE_NOT_FOUND,
    KobleErrors.USER_STUDENT_LIKE_ALREADY_EXISTS,
    KobleErrors.USER_STUDENT_LIKE_USER_STUDENT_NOT_COMPLETED,
    KobleErrors.USER_STUDENT_PREFERENCES_INDUSTRY_IDS_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_PREFERENCES_WORK_MODE_IDS_LIMIT_EXCEEDED,
    KobleErrors.USER_STUDENT_SOCIAL_MEDIA_INVALID_INSTAGRAM_USER_NAME,
    KobleErrors.USER_STUDENT_SOCIAL_MEDIA_INVALID_FACEBOOK_USER_NAME,
    KobleErrors.USER_STUDENT_SOCIAL_MEDIA_INVALID_LINKEDIN_USER_NAME,
    KobleErrors.USER_STUDENT_TOUR_INVALID_USER_STUDENT_TOUR,
    KobleErrors.WORK_CONTRACT_TYPE_ID_NOT_FOUND,
    KobleErrors.WORK_ENVIRONMENT_ID_NOT_FOUND,
    KobleErrors.WORK_EXPERIENCE_ID_NOT_FOUND,
    KobleErrors.WORK_EXPERIENCE_INVALID_BUSINESS_NAME,
    KobleErrors.WORK_EXPERIENCE_INVALID_START_DATE,
    KobleErrors.WORK_EXPERIENCE_INVALID_END_DATE,
    KobleErrors.WORK_EXPERIENCE_INVALID_ABOUT,
    KobleErrors.WORK_EXPERIENCE_INVALID_JOB_TITLE,
    KobleErrors.WORK_EXPERIENCE_INVALID_KEY_COMPETENCY_LIST,
    KobleErrors.WORK_MODE_ID_NOT_FOUND,
    KobleErrors.WORK_POSITION_ID_NOT_FOUND,
    KobleErrors.WORK_SCHEDULE_TYPE_ID_NOT_FOUND,
    KobleErrors.TWILIO_INVALID_COUNTRY_CODE,
    KobleErrors.TWILIO_INVALID_PHONE_NUMBER,
    KobleErrors.TWILIO_INVALID_CHANNEL,
    KobleErrors.TWILIO_INVALID_CODE,
    KobleErrors.TWILIO_INVALID_PHONE_VERIFICATION_CODE,
    KobleErrors.GOOGLE_INVALID_PLACE_ID,
    KobleErrors.GOOGLE_INVALID_CAPTCHA_TOKEN,
    KobleErrors.GOOGLE_INVALID_JWT_TOKEN,
    KobleErrors.AZURE_BLOB_FILE_NOT_FOUND,
  ];
}

const errors: { [key: string]: KobleException } = KobleErrors.all.reduce(
  (acc, error) => ({
    ...acc,
    [error.key]: error,
  }),
  {}
);

export default errors;
